import moment from 'moment'

export function utc2EDT(iso) {
  const eventDate = new Date(iso)
  const offsetMinutes = eventDate.getTimezoneOffset() // Get time offset to determine EDT vs ETS
  const adjustForDayLightSaving = !!(offsetMinutes === 240) // 240 is 4*60  minutes ==4 hours offset =EDT
  //   const adjustForDayLightSaving = offsetMinutes === 240 ? false : true // 240 is 4*60  minutes ==4 hours offset =EDT
  const edtOffset = adjustForDayLightSaving ? -5 : -4 // EDT is UTC-4 EST is UTC-5
  const estDate = new Date(eventDate.getTime() + edtOffset * 60 * 60 * 1000)

  // format the date in ISO string
  return estDate.toISOString()
}

export function isoStringConverter(isoDate, toParser, endDate) {
  const rawDate = new Date(isoDate)
  const offsetMinutes = rawDate.getTimezoneOffset() // Get time offset to determine EDT vs ETS
  const adjustForDayLightSaving = !!(offsetMinutes === 240) // 240 is 4*60  minutes ==4 hours offset =EDT
  const edtOffset = adjustForDayLightSaving ? -5 : -4 // EDT is UTC-4 EST is UTC-5
  const edtDateObj = new Date(rawDate.getTime() + edtOffset * 60 * 60 * 1000)
  const year = edtDateObj.getFullYear()
  let month = edtDateObj.getMonth() + 1
  let dt = edtDateObj.getDate()

  let ampm = edtDateObj.getHours() + edtOffset // EDT is UTC-4

  if (dt < 10) {
    dt = `0 ${dt}`
  }
  if (month < 10) {
    month = `0 ${month}`
  }
  // only use when special date formatting is needed
  if (ampm >= 12) {
    ampm = 'PM'
  } else {
    ampm = 'AM'
  }

  const options = { hour: 'numeric', minute: '2-digit', hour12: true }

  const formattedTime = edtDateObj.toLocaleTimeString('en-US', options)

  // const finalDate = new Date(year, month, dt)
  // const monthName = finalDate.toLocaleString('default', {
  //   month: 'long',
  // })

  if (toParser && !endDate) {
    return `${year} - ${month} - ${dt}`
  }
  if (!toParser && !endDate) {
    return `${moment(rawDate).format('MMMM D')} @ ${formattedTime}`
  }
  return `- ${formattedTime}`
}

export function formatOngoingEventDate(startsOn, endsOn) {
  const rawDate = new Date(startsOn)
  const offsetMinutes = rawDate.getTimezoneOffset() // Get time offset to determine EDT vs ETS
  const adjustForDayLightSaving = !!(offsetMinutes === 240) // 240 is 4*60  minutes ==4 hours offset =EDT
  const edtOffset = adjustForDayLightSaving ? -5 : -4

  const ongoingEventDate = `${moment
    .utc(startsOn)
    .add({ hours: edtOffset })
    .format('MMMM D [@] h:mm A')} - ${moment
    .utc(endsOn)
    .add({ hours: edtOffset })
    .format('MMMM D [@] h:mm A')}`

  return ongoingEventDate
}

/* @param asHours defaults to false . if true, function will return hours 
otherwise returns days as Boolean */
export function eventLength(startDate, endDate, asHours = false) {
  const eventStartEdtISO = utc2EDT(startDate)
  const eventEndEdtISO = utc2EDT(endDate)

  const eventStartDate = new Date(
    moment(eventStartEdtISO).format('MM/DD/YYYY HH:mm'),
  )
  const eventEndDate = new Date(
    moment(eventEndEdtISO).format('MM/DD/YYYY HH:mm'),
  )

  const differenceInTime = eventEndDate.getTime() - eventStartDate.getTime()

  // Calculating the no. of days between
  // two dates
  const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24))

  /* Calculating the duration of the event in hours */
  const differenceInHours = differenceInTime / (1000 * 3600)

  return asHours ? differenceInHours : Boolean(differenceInDays)
}

export function getHTMLfromStr(htmlStr) {
  const parser = new DOMParser()
  // convert html string into DOM
  const document = parser.parseFromString(htmlStr, 'text/html')
  const anchors = document.getElementsByTagName('a')
  const paragraphs = document.getElementsByTagName('body')

  const anchorsArr = Array.from(anchors)
  const bodyArr = Array.from(paragraphs)

  anchorsArr.forEach((current) => {
    current.setAttribute('target', 'blank')
  })
  const contentArr = Array.from(bodyArr[0].children)

  return contentArr
}

export function onLocationClick(params) {
  // address -> address_address
  // lng -> venue_geo_lng
  // lat => venue_geo_lat
  // venueWebsite => generated url engage site with state_eventid replaced by id !! 9754168 on 08/23/2024 due to ENGAGE api changes
  // imageUrl => imageUrl
  // Example link
  // test-my.rutgers.edu/uxp/maps?lng=-75.123222452105&lat=39.948869219927
  // &eventName=South%20Asian%20Small%20Group%20(Salvation%20House%20of%20Prayer,%20S.H.O.P.,%20InterVarsity)
  // &address=110%20Pearl%20Street,%20Camden,%20New%20Jersey
  // &venueWebsite=https://engage.camden.rutgers.edu/event/9754168
  // &imageUrl=https://se-images.campuslabs.com/clink/images/46cc8778-8175-45d3-8b0e-140c68999ba61867b2a3-7640-455f-bca4-78f35693044b.png

  function eventAddress() {
    if (params.address_name !== 'UNKNOWN' && params.venue === 'UNKNOWN') {
      return params.address_name
    }
    if (params.address_name === 'UNKNOWN' && params.venue !== 'UNKNOWN') {
      return params.venue
    }
    return params.address_name
  }
  if (typeof params === 'string') {
    window.open(params, '_blank')
  } else {
    const address = eventAddress()
    const mapURL = process.env.REACT_APP_MAPS_BASE_URL
    const engagePageURL = process.env.REACT_APP_ENGAGE_PAGE_LINK_BASE_URL
    let finalURL = `${mapURL}lng=${params.address_longitude}&lat=${params.address_latitude}&eventName=${params.name}&address=${address}&venueWebsite=${engagePageURL}/${params.id}&imageUrl=${params.imageUrl}`
    finalURL = finalURL.replace(/ /g, '%20')
    window.open(finalURL, '_blank')
  }
}
