/* eslint-disable import/no-unresolved */
/* eslint-disable radix */
import ColorContrastChecker from 'color-contrast-checker'

export function getCookie(name) {
  let value = ` ${document.cookie}`
  let start = value.indexOf(` ${name}=`)
  if (start === -1) {
    value = null
  } else {
    start = value.indexOf('=', start) + 1
    let end = value.indexOf(';', start)
    if (end === -1) {
      end = value.length
    }
    value = unescape(value.substring(start, end))
  }
  return value
}

export function isValidColorString(color) {
  const colorStringCheckReg = /^#([0-9A-F]{3}){1,2}$/i
  return colorStringCheckReg.test(color)
}

export function textColorBasedOnBackground(color1) {
  const ccc = new ColorContrastChecker()
  const checkedColor = isValidColorString(color1) ? color1 : '#FFBF00'
  return ccc.isLevelCustom(checkedColor, '#FFFFFF', 3) ? '#FFFFFF' : '#000000'
}

export const formatRefundDate = (originalDateString) => {
  // Parse the original date string into a Date object
  const originalDate = new Date(originalDateString)

  // Extract year, month, and day from the Date object
  const year = originalDate.getFullYear()
  const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Months are 0 indexed, so add 1
  const day = String(originalDate.getDate()).padStart(2, '0')

  // Formatted date string (YYYY-MM-DD)
  return `${year}-${month}-${day}`
}

export const formatAckDate = (acknowledgedDate) => {
  let dateACK = acknowledgedDate
  const firstDateACK = acknowledgedDate?.split('T')[0] || ''
  const [yearACK, monthACK, dayACK] = firstDateACK?.split('-')
  if (acknowledgedDate?.includes('T')) {
    dateACK = new Date(yearACK, monthACK - 1, dayACK)
  } else if (acknowledgedDate && acknowledgedDate !== '') {
    const parts = acknowledgedDate?.split(' ')
    const datePart = parts[0]
    const timePart = parts[1]

    const dateParts = datePart.split('-')
    const yearInt = parseInt(dateParts[0])
    const monthInt = parseInt(dateParts[1]) - 1 // Months are zero-indexed
    const dayInt = parseInt(dateParts[2])

    const timeParts = timePart.split(':')
    const hour = parseInt(timeParts[0])
    const minute = parseInt(timeParts[1])

    const secondsAndMilliseconds = timeParts[2].split('.')
    const second = parseInt(secondsAndMilliseconds[0])
    const millisecond = parseInt(secondsAndMilliseconds[1])

    dateACK = new Date(
      yearInt,
      monthInt,
      dayInt,
      hour,
      minute,
      second,
      millisecond,
    )
  }
  return dateACK
}

export const ensureHttps = (link) => {
  let httpsLink = link
  if (link && !/^https?:\/\//i.test(link)) {
    // If not, prepend "https://"
    httpsLink = `https://${link}`
  }
  return httpsLink
}

export const truncateText = (text, length) => {
  // pass a string and the cutoff length
  if (text.length <= length) {
    return text
  }
  return `${text.substr(0, length)}\u2026`
}
