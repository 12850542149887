import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Grid,
  IconButton,
  useTheme,
  Tooltip,
  Typography,
} from '@material-ui/core'
import ListAltIcon from '@material-ui/icons/ListAlt'

import {
  RutgersTabs,
  RutgersTab,
} from '../../../dashboard/DashboardComponents/Tabs/RutgersTabs'
import { ManageNewsStyles } from './ManageNewsStyles'
import {
  setNews,
  setOpenNewsReorderDialog,
  updateUnsubscribedSources,
} from '../../newsSlice'
import {
  extractSectionsFromNewsList,
  setSectionsOrder,
  setUnsubscribedNewsSource,
} from '../../helpers'
import {
  TabPanel,
  a11yProps,
} from '../../../dashboard/DashboardComponents/TabPanel/TabPanel'
import NewsSourceList from '../NewsSourceList/NewsSourceList'
import CheckIsMobile from '../../../../../utils/isMobileHook'
import ReorderableNewsSection from '../ReorderNewsSections/ReorderableNewsSection'
import Modal from '../../../../CommonComponents/Modal/Modal'

function SourcesPanel({
  newsStatus,
  unsubscribedList,
  setUnsubscribedList,
  setEdited,
  originalList,
  edited,
  setOpen,
}) {
  return (
    <>
      {newsStatus === 'fulfilled' && (
        <NewsSourceList
          unsubscribedList={unsubscribedList}
          setUnsubscribedList={(list) => {
            setUnsubscribedList(list)
            setEdited(true) // Setting edited to true when the list is updated
          }}
          originalList={originalList}
          setEdited={setEdited}
          edited={edited}
          setOpen={setOpen}
        />
      )}
    </>
  )
}

function CategoriesPanel({ newsSections, setNewsSections, setEdited }) {
  return (
    <ReorderableNewsSection
      newsSections={newsSections}
      setNewsSections={(sections) => {
        setNewsSections(sections)
        setEdited(true) // Setting edited to true when sections are updated
      }}
      setEdited={setEdited}
    />
  )
}

function TabsPanel({ classes, handleTabChange, tabValue }) {
  const tabs = ['Sources', 'Categories']

  return (
    <RutgersTabs
      value={tabValue}
      onChange={handleTabChange}
      variant="fullWidth"
    >
      {tabs.map((tab, index) => (
        <RutgersTab
          key={tab}
          label={tab}
          className={
            tabValue === index ? classes.activeTab : classes.inactiveTab
          }
          {...a11yProps(index)}
        />
      ))}
    </RutgersTabs>
  )
}

function FooterButton({ onSetClick, edited, tabValue, classes }) {
  const tab0HelperText = <>Subscribe / Unsubscribe to sources</>

  const tab1HelperText = <>Drag to reorder</>
  return (
    <>
      <Typography variant="body1" className={classes.helperText}>
        {tabValue === 0 ? tab0HelperText : tab1HelperText}
      </Typography>

      <Button
        onClick={onSetClick}
        variant="outlined"
        disabled={!edited}
        color="primary"
      >
        Set
      </Button>
    </>
  )
}

function ManageNews() {
  // Parent component that manages state for all it's children
  const dispatch = useDispatch()
  const classes = ManageNewsStyles()
  const openDialog = useSelector((s) => s.news.newsReorderDialogOpen)
  const isMobile = CheckIsMobile()
  const history = useHistory()
  const [tabValue, setTabValue] = React.useState(0)
  const [newsSections, setNewsSections] = React.useState([])
  const [unsubscribedList, setUnsubscribedList] = React.useState([])
  const { news, unsubscribedSources } = useSelector((s) => s.news)
  const [edited, setEdited] = React.useState(false)
  const [initialNewsSections, setInitialNewsSections] = React.useState([])
  const [initialUnsubscribedList, setInitialUnsubscribedList] = React.useState(
    [],
  )

  React.useEffect(() => {
    if (openDialog) {
      const mapped = Object.entries(news)
        .sort(([, a], [, b]) => a.order - b.order)
        .map(([, value]) => value)
      setNewsSections(mapped)
      setInitialNewsSections(mapped)
      setUnsubscribedList(unsubscribedSources)
      setInitialUnsubscribedList(unsubscribedSources)
    }
  }, [news, openDialog, unsubscribedSources])

  const setOpen = () => {
    if (isMobile) {
      history.push({
        pathname: './ManageNewsMobile',
      })
    } else if (openDialog) {
      dispatch(setOpenNewsReorderDialog(false))
    } else {
      dispatch(setOpenNewsReorderDialog(true))
    }
  }
  const handleClose = () => {
    setNewsSections(initialNewsSections)
    setUnsubscribedList(initialUnsubscribedList)
    setEdited(false)
    setTabValue(0)
    setOpen()
  }

  const updateOrder = () => {
    const updatedNewsSections = newsSections.map((item, index) => ({
      ...item,
      order: index,
    }))

    setSectionsOrder(extractSectionsFromNewsList(updatedNewsSections))
    dispatch(setNews(updatedNewsSections))
    setEdited(false)
  }

  const updateList = () => {
    setUnsubscribedNewsSource(unsubscribedList)
    dispatch(updateUnsubscribedSources(unsubscribedList))
    setEdited(false)
    setOpen()
  }

  const onSetClick = () => {
    updateOrder()
    updateList()
    setTabValue(0)
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <>
      <Tooltip
        title={<Typography variant="subtitle2">Manage News</Typography>}
        placement="top"
        arrow
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Manage News Modal"
          onClick={() => setOpen()}
        >
          <ListAltIcon className={classes.appBarIcon} />
        </IconButton>
      </Tooltip>
      <Modal
        open={openDialog}
        dialogTitle="Customize myNews"
        onCloseDialog={handleClose}
        dialogSubtitle={
          <TabsPanel
            classes={classes}
            handleTabChange={handleTabChange}
            tabValue={tabValue}
          />
        }
        dialogContent={
          <ManageNewsContent
            tabValue={tabValue}
            setOpen={setOpen}
            edited={edited}
            setEdited={setEdited}
            newsSections={newsSections}
            setNewsSections={setNewsSections}
            initialUnsubscribedList={initialUnsubscribedList}
            unsubscribedList={unsubscribedList}
            setUnsubscribedList={setUnsubscribedList}
          />
        }
        dialogActions={
          <FooterButton
            onSetClick={onSetClick}
            edited={edited}
            tabValue={tabValue}
            classes={classes}
          />
        }
      />
    </>
  )
}

function ManageNewsContent({
  tabValue,
  setOpen,
  edited,
  setEdited,
  newsSections,
  setNewsSections,
  initialUnsubscribedList,
  unsubscribedList,
  setUnsubscribedList,
}) {
  const theme = useTheme()
  const { newsStatus } = useSelector((s) => s.news)

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <TabPanel value={tabValue} index={0} dir={theme.direction}>
          <SourcesPanel
            newsStatus={newsStatus}
            unsubscribedList={unsubscribedList}
            setUnsubscribedList={setUnsubscribedList}
            setEdited={setEdited}
            originalList={initialUnsubscribedList}
            edited={edited}
            setOpen={setOpen}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1} dir={theme.direction}>
          <CategoriesPanel
            newsSections={newsSections}
            setNewsSections={setNewsSections}
            setEdited={setEdited}
          />
        </TabPanel>
      </Grid>
    </Grid>
  )
}

export default ManageNews
export { ManageNewsContent, FooterButton }
