import React from 'react'
import {
  Typography,
  IconButton,
  Grid,
  AppBar,
  Toolbar,
} from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import {
  setWidgetActive,
  setWidgetActiveLocal,
  selectWidgets,
  selectUserOrder,
  updateWidgets,
} from '../../dashboardSlice'
import AddWidgets from '../../DashboardComponents/AddWidgets/AddWidgets'
import ReorderWidgets from '../../DashboardComponents/ReorderWidgets/ReorderWidgets'
import { ManageWidgetsMobileStyles } from './ManageWidgetsMobileStyles'
import { ManageTabsMobile } from '../../DashboardComponents/ManageTabsMobile/ManageTabsMobile'
import { FooterButton } from '../../DashboardComponents/ManageWidgets/ManageWidgets'

function ManageWidgetsMobile() {
  const dispatch = useDispatch()
  const classes = ManageWidgetsMobileStyles()
  const history = useHistory()
  const [tabValue, setTabValue] = React.useState(0)
  const [edited, setEdited] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const userOrder = useSelector(selectUserOrder)
  const [selectedWidgets, setSelectedWidgets] = React.useState([])
  const [expandedIndicies, setExpandedIndicies] = React.useState([])
  const [selectedIndicies, setSelectedIndicies] = React.useState([])
  const [dashboardWidgets, setDashboardWidgets] = React.useState([])
  const widgets = useSelector(selectWidgets)

  React.useEffect(() => {
    setDashboardWidgets(widgets)
  }, [widgets])

  const managePanels = ['Add a widget', 'Reorder Widgets']

  const addWidget = () => {
    setSearch('')
    setTabValue(0)
    let modifiedUserOrder = []
    let selectedWidgetIds = []
    dashboardWidgets.forEach((user, index) => {
      modifiedUserOrder[index] = { ...user }
    })
    for (let i = 0; i < selectedIndicies.length; i++) {
      dispatch(setWidgetActiveLocal(selectedWidgets[i]))
      modifiedUserOrder = [selectedWidgets[i], ...modifiedUserOrder]
      selectedWidgetIds = [selectedWidgets[i].id, ...selectedWidgetIds]
    }
    dispatch(setWidgetActive(selectedWidgetIds))
    setEdited(false)
    return modifiedUserOrder
  }

  const updateOrder = (newOrder) => {
    dispatch(updateWidgets(newOrder))
    setEdited(false)
  }

  const onSetClick = () => {
    const newOrder = addWidget()
    updateOrder(newOrder)
    history.goBack()
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const exitPage = () => {
    history.goBack()
  }

  return (
    <div className={classes.container}>
      <AppBar className={classes.appBar} position="fixed" elevation={2}>
        <Toolbar className={classes.container}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item sm={4}>
              <IconButton onClick={() => exitPage()}>
                <ArrowBackIosIcon className={classes.mobileBackButtonIcon} />
              </IconButton>
            </Grid>
            <Grid item sm={4}>
              <Typography className={classes.pageTitle}>
                Manage Widgets
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={4}>
            <></>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.body}>
        <ManageTabsMobile
          tabs={managePanels}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          tabOne={
            <AddWidgets
              dispatch={dispatch}
              search={search}
              setSearch={setSearch}
              setEdited={setEdited}
              selectedWidgets={selectedWidgets}
              setSelectedWidgets={setSelectedWidgets}
              expandedIndicies={expandedIndicies}
              setExpandedIndicies={setExpandedIndicies}
              selectedIndicies={selectedIndicies}
              setSelectedIndicies={setSelectedIndicies}
            />
          }
          tabTwo={
            <ReorderWidgets
              dispatch={dispatch}
              setEdited={setEdited}
              dashboardWidgets={dashboardWidgets}
              setDashboardWidgets={setDashboardWidgets}
              updateWidgets={updateWidgets}
              widgets={widgets}
              userOrder={userOrder}
            />
          }
        />
      </div>
      <div className={classes.footerButton}>
        <FooterButton
          onSetClick={onSetClick}
          edited={edited}
          selectedIndicies={selectedIndicies}
          tabValue={tabValue}
          classes={classes}
        />
      </div>
    </div>
  )
}

export default ManageWidgetsMobile
