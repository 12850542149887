import * as React from 'react'
import { Typography, Box } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PropTypes from 'prop-types'
import OnlineEvent from './OnlineEvent'
import { onLocationClick } from './helpers/eventHelpers'
import { serverDataParams, eventTypes } from './eventCardParams'
/* If either venue or address is UNKNOWN display the avaliable info respectibely */
const VenueAndAddress = ({ addressName }) => (
  <Typography
    variant="body1"
    component="div"
    sx={{
      display: 'flex',
      flexDirection: 'row',
      textAlign: 'left',
    }}
  >
    {addressName !== serverDataParams.UNKNOWN_EVENT ? addressName : ''}
  </Typography>
)

/* If either venue or address is UNKNOWN and venue lat and lng is not provided do not diplsay location icon */
const LocationIconAndAddress = ({ venue, addressName, lat, lng, classes }) => {
  if (lat !== '' && lng !== '' && venue !== serverDataParams.UNKNOWN_EVENT) {
    return <LocationOnIcon className={classes.eventCardLocationIcon} />
  }
  if (
    lat !== '' &&
    lng !== '' &&
    addressName !== serverDataParams.UNKNOWN_EVENT
  ) {
    return <LocationOnIcon className={classes.eventCardLocationIcon} />
  }
  return null
}
const EventLocationAndVenueDisplay = ({
  classes,
  onlineURL,
  venue,
  venueGeoLat,
  venueGeoLng,
  props,
  addressName,
}) => {
  const openUrl = () => {
    const urlToPass = onlineURL !== '' ? onlineURL : props
    onLocationClick(urlToPass)
  }
  const handlePress = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      openUrl()
    }
  }
  return (
    <Box
      className={classes.eventCardIconAndDescription}
      onClick={openUrl}
      onKeyPress={handlePress}
      tabIndex={0}
    >
      {onlineURL ||
      venue.includes(
        eventTypes.VIRTUAL_EVENT || eventTypes.ONLINE_EVENT || eventTypes.ZOOM,
      ) ? (
        <OnlineEvent onlineURL={onlineURL} classes={classes} />
      ) : (
        <>
          <LocationIconAndAddress
            venue={venue}
            addressName={addressName}
            lat={venueGeoLat}
            lng={venueGeoLng}
            classes={classes}
          />
          <VenueAndAddress addressName={addressName} />
        </>
      )}
    </Box>
  )
}

EventLocationAndVenueDisplay.propTypes = {
  classes: PropTypes.object,
  onlineURL: PropTypes.string,
  venueGeoLat: PropTypes.string,
  venueGeoLng: PropTypes.string,
  venue: PropTypes.string,
  props: PropTypes.object,

  addressName: PropTypes.string,
}
export default EventLocationAndVenueDisplay
