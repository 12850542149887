import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import ArticleLayoutWithImage from './helpers/ArticleLayoutWithImage'
import ArticleLayoutWithoutImage from './helpers/ArticleLayoutWithoutImage'
import CheckIsMobile from '../../../../../utils/isMobileHook'
import useNewsSectionStyles from '../../Pages/NewsSection/NewsSectionStyles'

export default function ArticleLayout(props) {
  const classes = useNewsSectionStyles()
  const isMobile = CheckIsMobile()
  const {
    articlesWithImage = [],
    articlesWithoutImage = [],
    sectionName = '',
    isLoading,
    tag,
  } = props
  return (
    <Box className={classes.pageContainer}>
      {sectionName &&
        (isLoading ? (
          <Skeleton width={150} />
        ) : (
          <Typography
            component={tag !== 'rest' ? Link : null}
            to={
              tag !== 'rest'
                ? {
                    pathname: './NewsSourcePage',
                    search: `?id=${
                      tag === 'rutgersToday' ? 11 : tag
                    }&source=${sectionName}`,
                  }
                : null
            }
            variant="h1"
            className={classes.sectionHeader}
          >
            {sectionName}
          </Typography>
        ))}

      {articlesWithImage.length > 0 && (
        <ArticleLayoutWithImage
          classes={classes}
          articles={articlesWithImage}
          isDesktopView={!isMobile}
          isLoading={isLoading}
        />
      )}

      {articlesWithoutImage.length > 0 && (
        <ArticleLayoutWithoutImage
          articles={articlesWithoutImage}
          isLoading={isLoading}
        />
      )}
    </Box>
  )
}
