import React from 'react'

import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers'
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import Container from './dnd-kit/Container'
import { Item } from './dnd-kit/SortableItem'
import { ReorderableNewsSectionStyles } from './ReorderableNewsSectionStyles'

export default function ReorderableNewsSection({
  newsSections,
  setNewsSections,
  setEdited,
}) {
  const classes = ReorderableNewsSectionStyles()
  const [activeId, setActiveId] = React.useState()
  const sensors = useSensors(
    useSensor(PointerSensor, {
      delay: 5,
    }),
  )

  const handleDragStart = (event) => {
    const { active } = event
    const { id } = active
    const draggingSource = newsSections.find(
      (source) => parseInt(source.id, 10) === parseInt(id, 10),
    )
    setActiveId(draggingSource)
  }

  const handleDragEnd = (event) => {
    const { active, over } = event
    // eslint-disable-next-line no-shadow
    const { id: activeId } = active
    const { id: overId } = over
    const oldIndex = newsSections.findIndex(
      (widget) => parseInt(widget.id, 10) === parseInt(activeId, 10),
    )
    const newIndex = newsSections.findIndex(
      (widget) => parseInt(widget.id, 10) === parseInt(overId, 10),
    )
    const copy = newsSections.map((source) => ({
      ...source,
    }))
    copy[oldIndex].manuallyMoved = Date.now()
    const newArray = arrayMove(copy, oldIndex, newIndex)
    setEdited(true)
    setActiveId(null)
    setNewsSections(newArray)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <div className={classes.dndDiv}>
        <Container
          id="active"
          newsSections={newsSections}
          activeId={activeId}
        />
      </div>
      <DragOverlay modifiers={[restrictToParentElement]}>
        {activeId ? <Item source={activeId} overlay /> : null}
      </DragOverlay>
    </DndContext>
  )
}
