import { makeStyles } from '@material-ui/core/styles'
// import theme from '../../../../theme/theme'

export const ManageWidgetsMobileStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
  },
  appBar: {
    color: 'rgba(0,0,0,0.54)',
    backgroundColor: theme.palette.base.rutgersRed,
  },
  mobileBackButtonIcon: {
    fontSize: 24,
    color: theme.palette.base.white,
  },
  pageTitle: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    fontSize: 22,
    fontWeight: '400',
    textOverflow: 'ellipsis',
    color: theme.palette.base.white,
  },
  body: {
    paddingTop: 55,
    paddingBottom: 40,
  },
  footerButton: {
    height: '48px',
    width: '100%',
    backgroundColor: theme.palette.base.white,
    position: 'fixed',
    bottom: 0,
    padding: 8,
    borderTop: `1px solid ${theme.palette.base.lightGray}`,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  helperText: {
    marginRight: theme.spacing(1),
  },
}))
