import * as React from 'react'
import {
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import crStyles from '../crStyles'

const DropDownList = ({ classes, data }) => (
  <Grid container className={classes.accordionContainer}>
    {data.map((item) => (
      <Grid
        item
        key={item.title}
        style={{ marginBottom: '5px', width: '320px' }}
      >
        <Accordion className={classes.accordion}>
          <AccordionSummary
            className={classes.accordionHeaderContainer}
            expandIcon={<ExpandMoreIcon className={classes.downArrow} />}
          >
            <Typography variant="subtitle1">{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {item.content?.length === 0 ? (
              <Typography variant="body1">no items here!</Typography>
            ) : (
              <Grid container direction="column" spacing={0}>
                {item.content?.map((listItem) => (
                  <Grid item key={listItem} className={classes.accordionItem}>
                    <Typography variant="h2" className={classes.itemBullet}>
                      &bull;
                    </Typography>
                    <Typography
                      className={classes.accordionItemText}
                      variant="body1"
                    >
                      {listItem}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
    ))}
  </Grid>
)

const CRcheckList = (data) => {
  const classes = crStyles()
  return (
    <Box>
      <Box className={classes.contactCardsContainer}>
        <DropDownList classes={classes} data={data.data} />
      </Box>
    </Box>
  )
}
export default CRcheckList
