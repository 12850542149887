import apiClient from '../../../api/apiClient'
import { getBuildingList } from '../utils/helpers'

const ENDPOINTS = {
  RESTAURANTS: process.env.REACT_APP_RESTAURANT_ENDPOINT,
  BUILDINGS: process.env.REACT_APP_BUILDINGSEARCH_ENDPOINT,
  CAMPUS_BUILDINGS: process.env.REACT_APP_BUILDINGSEARCHMDB_ENDPOINT,
  PARKING: process.env.REACT_APP_MAPS_PARKING,
}

export const fetchRestaurantsApi = async (coord) => {
  try {
    const response = await apiClient.post(
      ENDPOINTS.RESTAURANTS,
      {
        lat: coord.lat,
        lon: coord.lng,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
    return response.data.results
  } catch (error) {
    console.error('Error fetching restaurants:', error)
    throw error
  }
}

export const fetchAllBuildings = async () => {
  const response = await apiClient.get(ENDPOINTS.BUILDINGS)
  const campusBuildings = getBuildingList(response.data)
  return {
    buildingsList: response.data,
    campusBuildings,
  }
}

export const fetchParkingNearBuildingApi = async () => {
  const response = await apiClient.get(ENDPOINTS.PARKING)
  return response.data
}
