import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { doc, collection, getDoc, setDoc } from 'firebase/firestore'
import WidgetsList from './widgets/WidgetsList'
import styles from './DashboardStyles'
import { fetchUser } from '../settings/settingsSlice'
import { ReorderWidgetsAdminDialog } from '../admin/components/ReorderWidgetsAdmin/ReorderWidgetsAdmin'
import EditWidgetAdmin from '../admin/components/EditWidgetsAdmin/EditWidgetAdmin'
import { AddWidgetAdminDialog } from '../admin/components/AddWidgetsAdmin/AddWidgetAdmin'
import { fetchDefaultWidgets } from '../admin/adminPageSlice'
import AlertAdmin from '../admin/components/AlertAdmin'
import { firestore } from '../../../utils/firebase'
// import { firestore } from '../../../utils/firebase'
// import { dashboardData } from '../admin/dashboardData'

import AlertSnackbar from './DashboardComponents/AlertSnackbar/AlertSnackbar'

function Dashboard() {
  const classes = styles()
  const dispatch = useDispatch()
  const settings = useSelector((s) => s.settings)
  const isAdmin = settings?.adminMode
  React.useEffect(() => {
    dispatch(fetchUser())
  }, [])

  React.useEffect(() => {
    if (settings?.status === 'fulfilled') {
      const checkForTutorialDefaults = async () => {
        // logic for defaulting tutorial mode booleans for roles
        // admit and admitComing
        if (
          settings?.data?.user?.roles?.includes('ruAdmit') ||
          settings?.data?.user?.roles?.includes('ruAdmitComing')
        ) {
          const userDocRef = doc(
            collection(firestore, 'users'),
            localStorage.getItem('user'),
            'settings',
            'tutorials',
          )
          const docData = await getDoc(userDocRef)
          const data = docData.data()

          const tutorials = [
            'show_my_grades_tutorial',
            'show_course_registration_tutorial',
            'show_course_schedule_tutorial',
            'show_my_degree_tutorial',
          ]

          if (
            data &&
            tutorials.every((tutorial) => data[tutorial] === undefined)
          ) {
            setDoc(userDocRef, {
              show_my_degree_tutorial: true,
              show_course_registration_tutorial: true,
              show_course_schedule_tutorial: true,
              show_my_grades_tutorial: true,
            })
          }
        }
      }
      checkForTutorialDefaults()
    }
  }, [settings])

  React.useEffect(() => {
    if (isAdmin) {
      dispatch(fetchDefaultWidgets())
    }
  }, [isAdmin])

  //   const testWidgetDocument = () => {
  //     firestore
  //       .collection('DefaultWidgetOrder')
  //       .doc('defaultOrder')
  //       .collection('settings')
  //       .doc('dashboard')
  //       .update({
  //         dashboardData,
  //         lastUpdate: Date.now(),
  //       })
  //   }
  return (
    <Box className={classes.centerPage}>
      {/* <button type="button" onClick={testWidgetDocument}>
        click to set collection
      </button> */}
      <WidgetsList isAdmin={isAdmin} />
      <AlertSnackbar />
      <AlertAdmin />
      <EditWidgetAdmin />
      <ReorderWidgetsAdminDialog />
      <AddWidgetAdminDialog />
    </Box>
  )
}

export default Dashboard
