import * as React from 'react'

import {
  Typography as Text,
  Card,
  Grid,
  CardActionArea,
  IconButton,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import AppAltStyles from './AppAltStyles'
import { createIcon, selectFavoritedAppAndUpdate } from '../../helpers'
import { addProfileLogEvent } from '../../../dashboard/widgets/MyProfile/myProfileSlice'
import { showSearchBarContents } from '../../appSlice'

function AppAlt({ app, isGuest = false, isMobile }) {
  const classes = AppAltStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const isNew = false

  const handleFavorited = (e) => {
    e.stopPropagation()
    if (!isGuest) {
      if (process.env.REACT_APP_FIREBASE_ON === 'true') {
        dispatch(
          addProfileLogEvent({
            module: 'Apps',
            object: 'AppTile',
            objectId: 'AppsButton',
            action: 'Click',
            actionContext: app.isFavorited ? 'UnFavorite' : 'Favorite',
            actionContextValue: app.id,
            rcpid: localStorage.getItem('rutgersEduRCPID'),
            timestamp: Date.now(),
            deviceType: 'Web',
          }),
        )
      }
      selectFavoritedAppAndUpdate(dispatch, app.id)
    }
  }

  const openLink = () => {
    if (process.env.REACT_APP_FIREBASE_ON === 'true') {
      dispatch(
        addProfileLogEvent({
          module: 'Apps',
          object: 'AppTile',
          objectId: 'AppsTitle',
          action: 'Click',
          actionContext: 'ExternalClick',
          actionContextValue: app.id,
          rcpid: localStorage.getItem('rutgersEduRCPID'),
          url: app.url,
          timestamp: Date.now(),
          deviceType: 'Web',
        }),
      )
    }
    window.open(app.url)
  }

  const getMaintenanceEndDate = () => {
    const endDate = new Date(null)
    endDate.setTime(app.maintenanceEndDate.seconds * 1000)
    return endDate.toLocaleString()
  }

  const showInfo = (e) => {
    e.stopPropagation()
    if (process.env.REACT_APP_FIREBASE_ON === 'true') {
      dispatch(
        addProfileLogEvent({
          module: 'Apps',
          object: 'AppTile',
          objectId: 'AppsButton',
          action: 'Click',
          actionContext: 'ViewInformation',
          actionContextValue: app.id,
          rcpid: localStorage.getItem('rutgersEduRCPID'),
          timestamp: Date.now(),
          deviceType: 'Web',
        }),
      )
    }
    dispatch(showSearchBarContents(false))
    history.push({
      pathname: isMobile ? '/ViewAppInfoPageMobile' : './ViewAppInfoPage',
      search: `?appID=${app.id}`,
    })
  }

  return (
    <Card className={classes.root} elevation={6}>
      <CardActionArea disabled={app.inMaintenance} onClick={() => openLink()}>
        <Grid container direction="column">
          <Grid
            item
            className={classes.iconContainer}
            style={{ backgroundColor: app.color }}
          >
            {createIcon(app.icon, '6x', 100)}
          </Grid>

          <Grid item className={classes.textContainer}>
            <Grid item className={classes.appName}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Text component="span" className={classes.appNameText}>
                    {app.title}
                  </Text>
                </Grid>

                {app.inMaintenance && (
                  <div>
                    <Grid item>
                      <Text
                        component="span"
                        className={classes.maintenanceInfo}
                      >
                        {`In maintenance til ${getMaintenanceEndDate()}`}
                      </Text>
                    </Grid>

                    <Grid item className={classes.maintenanceBanner}>
                      <Text
                        component="span"
                        className={classes.maintenanceText}
                      >
                        Out Of Service
                      </Text>
                    </Grid>
                  </div>
                )}

                {isNew && !app.inMaintenance && (
                  <Grid item className={classes.isNewBanner}>
                    <Text component="span" className={classes.isNewText}>
                      New
                    </Text>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.marginBottom}>
            {!app.inMaintenance && ( // If it's not in maintenance, show the buttons
              <Grid
                container
                alignItems="flex-end"
                justifyContent="flex-end"
                style={{ marginTop: '-5px' }}
              >
                <Grid item>
                  <IconButton
                    aria-label="add/remove favorited app"
                    onClick={handleFavorited}
                    className={classes.iconFocus}
                  >
                    {app.isFavorited ? (
                      <FavoriteIcon
                        className={classes.selectedFavoriteIconButton}
                      />
                    ) : (
                      <FavoriteBorderIcon
                        className={classes.iconButtonBorder}
                      />
                    )}
                  </IconButton>
                </Grid>

                <Grid item style={{ marginRight: '8px' }}>
                  <IconButton
                    aria-label="more info"
                    onClick={(e) => showInfo(e)}
                    className={classes.iconFocus}
                  >
                    <InfoIcon className={classes.iconButton} />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  )
}

export default AppAlt
