import React from 'react'
import { Box, Card, CardContent, Typography, Divider } from '@material-ui/core'
import WebOutlinedIcon from '@material-ui/icons/WebOutlined'
import IconPicker from '../../../../apps/AppComponents/IconPicker/IconPicker'
import crStyles from '../crStyles'
import { truncateText } from '../../../../../../utils/utils'

const CRcontact = ({ handleClick, data }) => {
  const classes = crStyles()

  const handlePress = (e, link) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      handleClick(link)
    }
  }
  return (
    <Box className={classes.contactCardsContainer}>
      {data.map((item) => (
        <Card variant="outlined" className={classes.contactCard}>
          <CardContent className={classes.contactCardContent}>
            <Typography variant="h3">
              <b>{item.title}</b>
            </Typography>
            <Divider light variant="fullWidth" className={classes.divider} />
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              {item.subtitle}
            </Typography>
            {item.linkTitles.map((title, index) => {
              let icon
              let link
              switch (title) {
                case 'Email':
                  icon = (
                    <IconPicker iconName="fa-regular fa-envelope" size="1x" />
                  )
                  link = `mailto:${item.linkValues[index]}`
                  break
                case 'Phone Number':
                  icon = (
                    <IconPicker iconName="fa-mobile-screen-button" size="1x" />
                  )
                  link = `tel:${item.linkValues[index]}`
                  break
                case 'Website':
                  icon = <WebOutlinedIcon />
                  link = item.linkValues[index]
                  break
                default:
                  icon = null
                  link = null
                  break
              }
              return (
                <>
                  <Typography variant="h5">
                    <b>{title}</b>
                  </Typography>
                  <Box
                    className={classes.contactIconTextContainer}
                    onClick={() => handleClick(link)}
                    onKeyPress={(e) => handlePress(e, link)}
                    tabIndex={0}
                  >
                    {icon}
                    <Typography variant="body" className={classes.contactText}>
                      {truncateText(item.linkValues[index], 32)}
                    </Typography>
                  </Box>
                </>
              )
            })}
          </CardContent>
        </Card>
      ))}
    </Box>
  )
}

export default CRcontact
