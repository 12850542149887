import React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { OverlayView } from '@react-google-maps/api'
import { SvgIcon } from '@material-ui/core'

import uuid from 'react-uuid'
import {
  handleStopClicked,
  handleStopOnRouteClicked,
} from '../../redux/myMapsSlice'
import {
  BUILDING,
  CAMDENENGAGEBUILDING,
  FIREBASEMARKER,
  RESTAURANT,
  ROUTE,
  ROUTES,
  STOPMARKER,
  VEHICLE,
} from '../../../../utils/constants'
import { logMapEvent } from '../../../../utils/events'
import customMarkerStyles from './customMarkerStyles'

const inactivePathBackground =
  'M96.094 13.165 C 72.063 17.409,56.570 31.523,50.912 54.326 L 49.805 58.789 49.805 163.477 L 49.805 268.164 50.912 272.628 C 56.934 296.896,76.003 312.836,100.431 314.019 L 105.355 314.258 108.227 317.773 C 131.518 346.275,163.548 375.710,179.871 383.610 C 197.768 392.273,213.893 389.831,233.203 375.533 C 250.876 362.446,275.252 338.571,292.174 317.773 L 295.035 314.258 299.959 314.019 C 324.387 312.836,343.457 296.896,349.478 272.628 L 350.586 268.164 350.586 163.477 L 350.586 58.789 349.478 54.326 C 344.260 33.292,330.580 19.566,309.462 14.174 L 305.273 13.104 201.172 13.049 C 143.916 13.018,96.631 13.070,96.094 13.165'

const inactivePathIcon =
  'M97.852 21.895 C 77.160 23.907,60.636 40.633,58.593 61.632 C 58.098 66.721,58.098 260.232,58.593 265.321 C 60.930 289.348,79.768 305.412,105.664 305.463 L 109.570 305.470 112.109 308.692 C 125.738 325.986,147.285 348.132,164.060 362.089 C 192.502 385.752,207.719 385.805,235.938 362.340 C 252.233 348.790,273.249 327.382,286.647 310.686 L 290.820 305.484 294.727 305.470 C 320.673 305.373,339.460 289.349,341.798 265.321 C 342.293 260.232,342.293 66.721,341.798 61.632 C 339.729 40.367,323.304 23.943,302.040 21.874 C 297.453 21.428,102.449 21.448,97.852 21.895 M212.188 61.517 C 292.349 70.894,330.930 165.201,280.347 228.125 C 231.124 289.357,133.896 274.485,104.723 201.262 C 76.260 129.818,135.668 52.566,212.188 61.517 M194.922 92.993 C 141.457 96.440,111.328 158.323,141.326 203.076 C 174.182 252.093,249.350 241.875,267.743 185.891 C 283.592 137.650,246.005 89.700,194.922 92.993'

const activePathBackground =
  'M96.094 13.165 C 72.063 17.409,56.570 31.523,50.912 54.326 L 49.805 58.789 49.805 163.477 L 49.805 268.164 50.912 272.628 C 56.934 296.896,76.003 312.836,100.431 314.019 L 105.355 314.258 108.227 317.773 C 131.518 346.275,163.548 375.710,179.871 383.610 C 197.768 392.273,213.893 389.831,233.203 375.533 C 250.876 362.446,275.252 338.571,292.174 317.773 L 295.035 314.258 299.959 314.019 C 324.387 312.836,343.457 296.896,349.478 272.628 L 350.586 268.164 350.586 163.477 L 350.586 58.789 349.478 54.326 C 344.260 33.292,330.580 19.566,309.462 14.174 L 305.273 13.104 201.172 13.049 C 143.916 13.018,96.631 13.070,96.094 13.165'

const activePathIcon =
  'M97.965 30.898 C 84.247 32.239,71.320 43.905,68.048 57.896 C 67.021 62.289,67.021 264.665,68.048 269.057 C 71.865 285.379,85.272 295.680,103.516 296.309 C 114.048 296.671,113.822 296.556,120.690 305.046 C 136.808 324.971,160.216 348.300,176.012 360.181 C 199.622 377.940,209.851 375.129,246.510 340.810 C 257.501 330.521,269.301 317.813,279.898 304.857 C 286.766 296.460,286.346 296.671,296.875 296.309 C 315.119 295.680,328.526 285.379,332.342 269.057 C 333.369 264.665,333.369 62.289,332.342 57.896 C 329.426 45.422,318.508 34.429,305.974 31.344 C 303.017 30.617,105.185 30.192,97.965 30.898 M267.296 63.750 C 282.995 68.084,295.197 80.286,299.532 95.985 L 300.586 99.805 300.586 163.477 L 300.586 227.148 299.532 230.968 C 295.197 246.667,282.995 258.869,267.296 263.203 L 263.477 264.258 199.805 264.258 L 136.133 264.258 132.314 263.203 C 116.615 258.869,104.412 246.667,100.078 230.968 L 99.023 227.148 99.023 163.477 L 99.023 99.805 100.078 95.985 C 104.668 79.359,117.908 66.808,134.570 63.290 C 137.949 62.577,140.521 62.551,200.781 62.622 L 263.477 62.695 267.296 63.750 M195.016 111.342 C 155.905 114.583,134.290 159.552,156.065 192.383 C 180.058 228.559,235.697 221.382,249.417 180.340 C 261.480 144.256,233.046 108.191,195.016 111.342'

function CustomMarker({ stop, selectedRoute }) {
  const classes = customMarkerStyles()
  const dispatch = useDispatch()
  const { bottomSheet, selectedStop, showSearchBar } = useSelector(
    (s) => s.maps,
  )

  const updateHandleMarkerIsActive = () => {
    if (_.isEmpty(selectedStop) || selectedStop.stop_id !== stop.stop_id) {
      handleFetchStopLogic(stop)
    }
  }
  const handleFetchStopLogic = (markerStop) => {
    const { component } = bottomSheet

    if (
      [
        null,
        ROUTES,
        BUILDING,
        RESTAURANT,
        FIREBASEMARKER,
        CAMDENENGAGEBUILDING,
      ].includes(component)
    ) {
      logMapEvent(dispatch, STOPMARKER, markerStop.stop_name)
      dispatch(handleStopClicked(markerStop))
    } else if ([ROUTE, VEHICLE].includes(component)) {
      dispatch(handleStopOnRouteClicked(markerStop))
    }
  }

  const getFontSize = () => (stop.stop_id === selectedStop.stop_id ? 45 : 30)

  return (
    <button
      type="button"
      aria-label="Custom Marker"
      onClick={updateHandleMarkerIsActive}
    >
      <OverlayView
        key={uuid()}
        position={{
          lat: stop.stop_lat,
          lng: stop.stop_lon,
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div style={{ position: 'relative' }}>
          <SvgIcon
            viewBox="0 0 512 512"
            style={{ fontSize: getFontSize() }}
            htmlColor={
              selectedRoute ? selectedRoute.route_text_color : '#ffffff'
            }
            classes={{
              root: showSearchBar ? classes.iconPointerDisabled : classes.icon,
            }}
          >
            <path
              d={
                stop.stop_id === selectedStop.stop_id &&
                selectedStop.markerClicked
                  ? activePathBackground
                  : inactivePathBackground
              }
            />
          </SvgIcon>
          <SvgIcon
            viewBox="0 0 512 512"
            style={{ fontSize: getFontSize() }}
            htmlColor={selectedRoute ? selectedRoute.route_color : '#cc0033'}
            classes={{
              root: showSearchBar ? classes.iconPointerDisabled : classes.icon,
            }}
          >
            <path
              d={
                stop.stop_id === selectedStop.stop_id
                  ? activePathIcon
                  : inactivePathIcon
              }
            />
          </SvgIcon>
        </div>
      </OverlayView>
    </button>
  )
}

export default React.memo(CustomMarker)
