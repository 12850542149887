import React from 'react'
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styles from './ModalStyles'

// reusable modal broken into parts for ease of use
// mandatory: dialogTitle, dialogContent, open, onCloseDialog
// optional: dialogSubtitle, dialogActions
function Modal({
  open,
  onCloseDialog,
  dialogTitle,
  dialogContent,
  dialogSubtitle,
  dialogActions,
}) {
  const classes = styles()

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <DialogTitle>
        <Grid
          container
          direction="row"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h5">{dialogTitle}</Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onCloseDialog}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {dialogSubtitle && dialogSubtitle}
      <DialogContent dividers className={classes.dialog}>
        {dialogContent}
      </DialogContent>
      {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
    </Dialog>
  )
}

export default Modal
