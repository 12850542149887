import { makeStyles } from '@material-ui/core/styles'

export const useBottomSheetStyles = makeStyles((theme) => {
  const baseRootStyles = {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    borderStartEndRadius: 24,
    borderTopLeftRadius: 24,
    background: theme.palette.backgroundColor.main,
  }

  const baseMobileStyles = {
    [theme.breakpoints.down(640)]: {
      width: '100%',
      bottom: 10,
    },
  }

  return {
    root: {
      ...baseRootStyles,
      ...baseMobileStyles,
      [theme.breakpoints.down(640)]: {
        height: 200,
      },
    },
    searchRoot: {
      ...baseRootStyles,
      ...baseMobileStyles,
      [theme.breakpoints.down(640)]: {
        height: '83vh',
      },
    },
    specialRoot: {
      ...baseRootStyles,
      [theme.breakpoints.down(640)]: {
        height: '25vh',
        bottom: 0,
      },
    },
    routeItemsRoot: {
      ...baseRootStyles,
      [theme.breakpoints.down(640)]: {
        height: 180,
        bottom: 0,
      },
    },
    backButton: {
      position: 'absolute',
      left: 12,
      top: -3,
    },
    closeButton: {
      position: 'absolute',
      right: -3,
      top: -7,
    },
    appBarIcon: {
      fontSize: 22,
      color: theme.palette.components.bottomSheetOverlay,
    },
    backIcon: {
      fontSize: 18,
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.54)',
    },
    whiteBackIcon: {
      fontSize: 18,
      borderRadius: '50%',
      backgroundColor: theme.palette.components.white,
    },
    title: {
      fontWeight: 700,
      textOverflow: 'ellipses',
      whiteSpace: 'nowrap',
      overflow: 'hidde',
      [theme.breakpoints.down(640)]: {
        fontSize: 18,
      },
    },
    solidTitle: {
      color: theme.palette.text.ternary,
      fontWeight: 700,
      whiteSpace: 'nowrap',
      overflow: 'hidde',
      textOverflow: 'ellipses',
      backgroundColor: theme.palette.backgroundColor.ternary,
      padding: '4px 12px',
      borderRadius: '6px',
      margin: '6px 0 6px 0',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.down(640)]: {
        fontSize: 18,
      },
    },
  }
})
