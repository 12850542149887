import React from 'react'

import { Typography, Grid, Box, ButtonBase, Card } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { useHistory } from 'react-router-dom'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import styles from '../settingsStyles'

function Copyrights() {
  const classes = styles()
  const history = useHistory()
  return (
    <Box className={classes.centerPage}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.sectionTitle}
        >
          <ButtonBase
            onClick={() => history.push('/settings/appinfo')}
            aria-label="Back"
          >
            <ArrowBackIos />
          </ButtonBase>
          <Typography variant="h1">
            <b>Copyright Information</b>
          </Typography>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <Grid
              container
              direction="column"
              alignItems="left"
              justifyContent="flex-start"
              spacing={2}
              className={classes.copyrightCard}
            >
              <Grid
                item
                container
                direction="row"
                alignItems="left"
                justifyContent="flex-start"
              ></Grid>
              <Grid item>
                <Typography variant="h6">
                  <Link
                    href="https://www.rutgers.edu/copyright-information"
                    target="_blank"
                    rel="noopener noreferer"
                  >
                    Copyright ©
                  </Link>{' '}
                  <Link
                    href="https://www.rutgers.edu"
                    target="_blank"
                    rel="noopener noreferer"
                  >
                    Rutgers, The State University of New Jersey. All rights
                    reserved.
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Copyrights
