import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core'
import DOMPurify from 'dompurify'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import announcementsStyles from './announcementsStyles'
import { fetchAnnouncements } from '../loginSlice'

const Announcements = () => {
  const classes = announcementsStyles()
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(fetchAnnouncements())
  }, [])
  const { announcements } = useSelector((s) => s.login)
  if (announcements?.length < 0) {
    return null
  }

  return (
    <Box className={classes.announcementsContainer}>
      {announcements.map((announcement) => (
        <AnnouncementCard
          key={announcement.id}
          announcement={announcement}
          classes={classes}
        />
      ))}
    </Box>
  )
}

const AnnouncementCard = ({ announcement, classes }) => {
  console.log('announcement')
  return (
    <Card
      variant="outlined"
      className={classes.announcementContainer}
      key={announcement.id}
    >
      <CardHeader
        title={announcement.title}
        subheader={`Posted: ${announcement.date}`}
      />
      <CardMedia
        component="img"
        height="auto"
        image={announcement.image}
        alt={announcement.id}
      />
      <CardContent>
        <Typography variant="body1">
          <div
            // eslint-disable-next-line react/no-danger
            className={classes.descriptionStyle}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(announcement.message),
            }}
          />
        </Typography>
      </CardContent>
    </Card>
  )
}

export default Announcements
