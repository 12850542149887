import {
  Grid,
  Typography,
  TextField,
  Divider,
  Paper,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core'
import clsx from 'clsx'

import * as React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import { getUsersAddableWidgets } from '../../dashboardSlice'
import Widget from '../../widgets/Widget'
import Loading from '../../widgets/helpers/Loading'
import { getImageFromFirebaseWithHook } from '../../../apps/helpers'
import { AddWidgetsStyles } from './AddWidgetsStyles'

function AddWidgets({
  dispatch,
  search,
  setSearch,
  selectedWidgets,
  setSelectedWidgets,
  expandedIndicies,
  setExpandedIndicies,
  selectedIndicies,
  setSelectedIndicies,
  setEdited,
}) {
  const widgets = useSelector((s) => s.dashboard.usersAddableWidgets)
  const classes = AddWidgetsStyles()
  const usersAddableWidgetsStatus = useSelector(
    (s) => s.dashboard.usersAddableWidgetsStatus,
  )
  const [tabValue, setTabValue] = React.useState(0)

  React.useEffect(() => {
    dispatch(getUsersAddableWidgets()).then(() => {
      setSelectedIndicies([])
      setExpandedIndicies([])
      setSelectedWidgets([])
    })
  }, [])

  function checkActive(index) {
    return selectedIndicies.includes(index)
  }

  function checkExpanded(index) {
    return expandedIndicies.includes(index)
  }

  function toggleIndex(index) {
    if (checkActive(index)) {
      setSelectedIndicies(selectedIndicies.filter((entry) => entry !== index))
    } else {
      setSelectedIndicies(selectedIndicies.concat(index))
    }
  }

  function toggleWidget(widget, index) {
    if (checkActive(index)) {
      setSelectedWidgets(selectedWidgets.filter((entry) => entry !== widget))
    } else {
      setSelectedWidgets(selectedWidgets.concat(widget))
    }
    setEdited(true)
  }

  const handleTextInput = (event) => {
    setSearch(event.target.value)
  }

  const handleChange = (event) => {
    setSearch('')
    setSelectedIndicies([])
    setSelectedWidgets([])
    setTabValue(event.target.value)
  }

  const handleKeyDown = (event, value) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setSearch('')
      setSelectedIndicies([])
      setSelectedWidgets([])
      setTabValue(value)
    }
  }

  const tabs = [
    'All',
    'Academic',
    'Finance',
    'HR',
    'Campus',
    'Health',
    'Technology',
    'School',
    'Administration',
    'Career',
  ]

  const ids = Array.from(
    new Set(
      widgets
        .filter((widget) => widget.showOnDashboard)
        .map((widget) => widget.category),
    ),
  )

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.container}
    >
      <Grid item>
        <Grid
          item
          container
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Grid item xs className={classes.searchBar}>
            {/* TODO: replace with SearchBar.js */}
            <TextField
              label="Search Widgets"
              aria-label="Search Widgets"
              size="small"
              onChange={handleTextInput}
              value={search}
            />
          </Grid>
          <Grid item>
            <FormControl size="small" className={classes.form}>
              <Select
                value={tabValue}
                onChange={handleChange}
                aria-label="Categories drop-down"
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                }}
              >
                <MenuItem
                  key={tabs[0]}
                  value={0}
                  onKeyDown={(event) => handleKeyDown(event, 0)}
                >
                  <Typography align="left" className={classes.dropDownListItem}>
                    All
                  </Typography>
                </MenuItem>
                {ids.map((id) => (
                  <MenuItem
                    key={tabs[id]}
                    value={id}
                    onKeyDown={(event) => handleKeyDown(event, id)}
                  >
                    <Typography
                      align="left"
                      className={classes.dropDownListItem}
                    >
                      {tabs[id]}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Widget.Spacer />
        <Divider />
      </Grid>
      <Grid item container spacing={2} className={classes.content}>
        {usersAddableWidgetsStatus === 'fulfilled' ? (
          <WidgetList
            widgets={widgets}
            classes={classes}
            tabValue={tabValue}
            search={search}
            checkActive={checkActive}
            checkExpanded={checkExpanded}
            toggleIndex={toggleIndex}
            toggleWidget={toggleWidget}
          />
        ) : (
          <Loading />
        )}
      </Grid>
    </Grid>
  )
}

function WidgetList({
  widgets,
  classes,
  tabValue,
  search,
  checkActive,
  toggleIndex,
  toggleWidget,
}) {
  const filterWidget = (widget) =>
    widget.showOnDashboard &&
    (widget.title?.toLowerCase().includes(search?.toLowerCase()) ||
      widget.desc?.toLowerCase().includes(search?.toLowerCase())) &&
    widget.showOnDashboard &&
    (widget.category === tabValue || tabValue === 0)

  return widgets
    .filter((widget) => filterWidget(widget))
    .map((widget, index) => {
      const active = checkActive(index)
      return (
        <Grid item md={6} key={widget.title} className={classes.gridWidgetCard}>
          <WidgetCard
            widget={widget}
            active={active}
            classes={classes}
            toggleIndex={toggleIndex}
            toggleWidget={toggleWidget}
            index={index}
          />
        </Grid>
      )
    })
}

function WidgetCard({
  widget,
  active,
  classes,
  toggleIndex,
  toggleWidget,
  index,
}) {
  const [img, setImg] = React.useState('./assets/images/image-not-found.png')
  const theme = useTheme()

  React.useEffect(() => {
    if (widget.screenshot) {
      getImageFromFirebaseWithHook(widget.screenshot, setImg)
    }
  }, [widget])
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      toggleIndex(index)
      toggleWidget(widget, index)
    }
  }
  return (
    <Paper
      variant="outlined"
      className={clsx(classes.widgetPaper, {
        [classes.selectedPaper]: active,
      })}
      onClick={() => {
        toggleIndex(index)
        toggleWidget(widget, index)
      }}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      aria-label={`${widget.title}${active ? ' selected,' : ','} ${
        widget.desc
      } ,  press the space bar to select or deselect`}
    >
      <Grid container direction="column" className={classes.widgetPaperPadding}>
        <Grid item container style={{ position: 'relative' }}>
          <Grid item className={classes.gridWidgetImage}>
            <img className={classes.widgetImage} src={img} alt={widget.title} />
          </Grid>
          <Grid item style={{ position: 'absolute', padding: 10 }}>
            {active && (
              <div className={classes.backgroundDiv}>
                {active && (
                  <IconPicker
                    iconName="check-circle"
                    color={theme.palette.base.rutgersRed}
                    size="2x"
                  />
                )}
              </div>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          justifyContent="space-between"
          className={classes.titlePadding}
        >
          <Grid item>
            <Typography variant="h5">{widget.title}</Typography>
          </Grid>
        </Grid>
        <Divider />
        <Widget.Spacer />
        <Typography className={classes.subtitlePadding} variant="body1">
          {widget.desc}
        </Typography>
      </Grid>
    </Paper>
  )
}

export default AddWidgets
