import { makeStyles } from '@material-ui/core/styles'

export const AddWidgetsStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.paper.secondary,
  },
  content: {
    padding: theme.spacing(2),
  },
  searchBar: {
    paddingLeft: theme.spacing(1),
  },
  form: {
    paddingRight: theme.spacing(1),
  },
  dropDownListItem: {
    width: '100%',
  },
  gridWidgetCard: {
    width: '100%',
  },
  widgetPaper: {
    width: '100%',
    backgroundColor: theme.palette.backgroundColor.ternary,
    cursor: 'pointer',
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
    },
  },
  widgetPaperPadding: {
    padding: theme.spacing(0.5),
  },
  selectedPaper: {
    borderColor: theme.palette.base.red,
    cursor: 'pointer',
    border: '2px solid',
  },
  gridWidgetImage: {
    textAlign: 'center',
    width: '100%',
  },
  widgetImage: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      paddingTop: '10px',
      textAlign: 'center',
    },
  },
  backgroundDiv: {
    backgroundColor: theme.palette.base.white,
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titlePadding: {
    padding: '10px',
    paddingLeft: theme.spacing(0.5),
  },
  subtitlePadding: {
    paddingLeft: theme.spacing(0.5),
  },
}))
