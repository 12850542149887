import { makeStyles } from '@material-ui/core/styles'
// TODO: Make these colors match properly instead of hardcoded
export const glanceMenuStyles = makeStyles((theme) => ({
  swipeableDrawer: {
    position: 'absolute',
  },
  sideBar: {
    flexDirection: 'column',
    display: 'flex',
    height: '100% !important',
    transition: ' 0.8s ease',
    boxShadow:
      '0 4px 15px 0 rgba(0, 0, 0, 0.2), 0 6px 27px 0 rgba(0, 0, 0, 0.19)',
    borderColor: theme.palette.base.red,
    backgroundColor: theme.palette.background.paper,
  },
  toggleMenu: {
    height: '60px',
    width: '20px',
    borderTopRightRadius: '10rem',
    borderBottomRightRadius: '10rem',
    transform: 'translate(20px, 20px) scale(2,1)',
    borderColor: theme.palette.base.red,
    position: 'absolute',
    outline: 'none',
    zIndex: 1,
    transition: ' 0.8s ease',
    backgroundColor: theme.palette.background.paper,
  },
  menuButtonIcon: {
    color: theme.palette.base.red,
    fontSize: 20,
  },
  listItemContainer: {
    marginTop: 7,
    marginBottom: 7,
    marginLeft: 24,
  },
  listContainer: {
    marginTop: 24,
  },
  textContainer: {
    marginLeft: 32,
  },
  text: {
    fontSize: 16,
    color: theme.palette.base.red,
  },
  editButton: {
    backgroundColor: theme.palette.background.widgetSecondaryBackground,
    borderRadius: 20,
    height: 25,
    marginTop: 20,
  },
  editButtonText: {
    color: theme.palette.base.red,
    fontSize: 12,
  },
  buttonWidth: {
    width: '100%',
  },
  buttonReorder: {
    width: '100%',
    height: '50px',
    boxShadow: '0 0 5px rgba(0,0,0,0.16)',
    '&:hover': {
      cursor: 'grab',
    },
  },
  button: {
    height: '44px',
    padding: '6px',
    width: '100%',
    margin: '2px auto',
    justifyContent: 'flex-start',
    border: '2px solid blue',
    borderColor: theme.palette.base.lightGray,
  },
  buttonZIndex: {
    zIndex: theme.zIndex.modal + 100,
  },
  selectedButton: {
    height: '44px',
    padding: '6px',
    width: '100%',
    margin: '2px auto',
    justifyContent: 'flex-start',
    border: '2px solid blue',
    borderColor: theme.palette.base.red,
  },
  tabs: {
    width: '100%',
  },
  dialog: {
    height: '80vh',
  },
  dialogMain: {
    height: '90vh',
  },
  dialogContent: {
    padding: '5px 5px',
  },
  desc: {
    display: 'flex',
    alignContent: 'flex-end',
    justifyItems: 'flex-end',
  },
  textField: {
    width: '100%',
    height: '48px',
    margin: '0 auto',
  },
  paper: {
    maxWidth: '100%',
    padding: '5px',
  },
  verticalContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: 'calc(100% - 55px)',
  },
  scrollContainer: {
    overflowY: 'auto',
    flexGrow: 1,
    padding: '5px',
    maxHeight: '100%',
  },
  popupContainer: {
    flexShrink: 1,
  },
  reorderListStyles: {
    margin: '5px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  header: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '.9rem',
    color: theme.palette.primary.main,
    '-webkit-user-select': 'none' /* Safari */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* IE10+/Edge */,
    'user-select': 'none' /* Standard */,
  },
  paperContainer: {
    height: '100%',
  },
  widgetList: {
    flex: 1,
    overflow: 'auto',
  },
  footer: {
    borderTop: '1px solid',
    borderColor: theme.palette.backgroundColor.ternary,
    backgroundColor: theme.palette.backgroundColor.secondary,
  },
  centerNoteIcon: {
    paddingLeft: '7px',
  },
  glanceMenuButtonContainer: {
    marginLeft: 230,
    position: 'fixed',
    zIndex: 1301,
    background: 'white',
    width: 30,
    height: 75,
    borderRadius: '100% / 50%',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  glanceMenuIcon: {
    marginLeft: 3,
    marginTop: 27,
    color: theme.palette.base.red,
  },
  glanceMenuIconClosed: {
    marginLeft: -1,
    marginTop: 21,
    color: theme.palette.base.red,
  },
  glanceMenuListItem: {
    color: theme.palette.base.red,
  },
  glanceMenuButtonOpen: {
    backgroundColor: theme.palette.backgroundColor.secondary,
    marginLeft: 250,
    position: 'fixed',
    zIndex: 1301,
    background: 'white',
    width: 30,
    height: 75,
    borderRadius: '100% / 50%',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  glanceMenuButtonClosed: {
    backgroundColor: theme.palette.backgroundColor.secondary,
    marginLeft: 0,
    position: 'fixed',
    zIndex: 1301,
    background: 'white',
    width: 20,
    height: 65,
    borderRadius: '100% / 50%',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}))

export const reorderGlanceMenuMobileStyles = makeStyles((theme) => ({
  editButton: {
    backgroundColor: theme.palette.background.widgetSecondaryBackground,
    borderRadius: 20,
    height: 25,
    marginBottom: 20,
    marginTop: 20,
  },
  editButtonText: {
    color: theme.palette.base.red,
    fontSize: 12,
  },
  helper: {
    backgroundColor: 'rgb(136,136,136,0.1)',
  },
  widgetDiv: {
    marginBottom: 20,
  },
}))

export default glanceMenuStyles
