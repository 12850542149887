import React from 'react'
import {
  Typography as Text,
  IconButton,
  Grid,
  AppBar,
  Toolbar,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useTheme } from '@material-ui/core/styles'
import { ManageNewsMobileStyles } from './ManageNewsMobileStyles'
import NewsSourceList from '../../components/NewsSourceList/NewsSourceList'
import {
  setNews,
  setOpenNewsReorderDialog,
  updateUnsubscribedSources,
} from '../../newsSlice'
import { NewsTabsMobile } from '../../components/NewsTabsMobile/NewsTabsMobile'
import ReorderableNewsSection from '../../components/ReorderNewsSections/ReorderableNewsSection'
import {
  extractSectionsFromNewsList,
  setSectionsOrder,
  setUnsubscribedNewsSource,
} from '../../helpers'
import CheckIsMobile from '../../../../../utils/isMobileHook'
import { FooterButton } from '../../components/ManageNews/ManageNews'

function SourcesPanel({
  newsStatus,
  unsubscribedList,
  setUnsubscribedList,
  setEdited,
  originalList,
  edited,
  setOpen,
}) {
  return (
    <>
      {newsStatus === 'fulfilled' && (
        <NewsSourceList
          unsubscribedList={unsubscribedList}
          setUnsubscribedList={(list) => {
            setUnsubscribedList(list)
            setEdited(true) // Setting edited to true when the list is updated
          }}
          originalList={originalList}
          setEdited={setEdited}
          edited={edited}
          setOpen={setOpen}
        />
      )}
    </>
  )
}

function CategoriesPanel({ newsSections, setNewsSections, setEdited }) {
  return (
    <>
      <ReorderableNewsSection
        newsSections={newsSections}
        setNewsSections={setNewsSections}
        setEdited={setEdited}
      />
    </>
  )
}

function ManageNewsMobile() {
  const dispatch = useDispatch()
  const classes = ManageNewsMobileStyles()
  const history = useHistory()
  const isMobile = CheckIsMobile()
  const openDialog = useSelector((s) => s.news.newsReorderDialogOpen)
  const { news, newsStatus, unsubscribedSources } = useSelector((s) => s.news)
  const [unsubscribedList, setUnsubscribedList] = React.useState([])
  const [initialUnsubscribedList, setInitialUnsubscribedList] = React.useState(
    [],
  )
  const [newsSections, setNewsSections] = React.useState([])
  const [edited, setEdited] = React.useState(false)
  const theme = useTheme()
  const [tabValue, setTabValue] = React.useState(0)

  const newsPanels = ['Sources', 'Categories']

  React.useEffect(() => {
    setUnsubscribedList(unsubscribedSources)
    setInitialUnsubscribedList(unsubscribedSources)
  }, [unsubscribedSources])

  React.useEffect(() => {
    const mapped = Object.entries(news)
      .sort(([, a], [, b]) => a.order - b.order)
      .map(([, value]) => value)
    setNewsSections(mapped)
  }, [news])

  const setOpen = () => {
    if (isMobile) {
      history.push({
        pathname: './ManageNewsMobile',
      })
    } else if (openDialog) {
      dispatch(setOpenNewsReorderDialog(false))
    } else {
      dispatch(setOpenNewsReorderDialog(true))
    }
  }

  const updateOrder = () => {
    const updatedNewsSections = newsSections.map((item, index) => ({
      ...item,
      order: index,
    }))
    setSectionsOrder(extractSectionsFromNewsList(updatedNewsSections))
    dispatch(setNews(updatedNewsSections))
    setEdited(false)
  }

  const updateList = () => {
    setUnsubscribedNewsSource(unsubscribedList)
    dispatch(updateUnsubscribedSources(unsubscribedList))
    setEdited(false)
  }

  const exitPage = () => {
    setUnsubscribedNewsSource(unsubscribedList)
    dispatch(updateUnsubscribedSources(unsubscribedList))
    updateOrder()
    history.goBack()
  }

  const onSetClick = () => {
    updateOrder()
    updateList()
    history.goBack()
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <div className={classes.container}>
      <AppBar
        className={classes.appBar}
        position="fixed"
        elevation={2}
        style={{ backgroundColor: theme.palette.base.rutgersRed }}
      >
        <Toolbar className={classes.container}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item sm={4}>
              <IconButton onClick={() => exitPage()}>
                <ArrowBackIosIcon className={classes.mobileBackButtonIcon} />
              </IconButton>
            </Grid>
            <Grid item sm={4}>
              <Text className={classes.pageTitle}>Customize myNews</Text>
            </Grid>
          </Grid>
          <Grid item sm={4} />
        </Toolbar>
      </AppBar>
      <div className={classes.body}>
        <NewsTabsMobile
          tabs={newsPanels}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          tabOne={
            <SourcesPanel
              newsStatus={newsStatus}
              unsubscribedList={unsubscribedList}
              setUnsubscribedList={setUnsubscribedList}
              setEdited={setEdited}
              originalList={initialUnsubscribedList}
              edited={edited}
              setOpen={setOpen}
            />
          }
          tabTwo={
            <CategoriesPanel
              newsSections={newsSections}
              setNewsSections={setNewsSections}
              setEdited={setEdited}
            />
          }
        />
      </div>
      <div item className={classes.footerButton}>
        <FooterButton
          onSetClick={onSetClick}
          edited={edited}
          tabValue={tabValue}
          classes={classes}
        />
      </div>
    </div>
  )
}

export default ManageNewsMobile
