import {
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  Button,
  Typography,
  Divider,
  Slide,
} from '@material-ui/core'

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  addEditRemoveWidgetsInFireStore,
  setEditDialog,
  setFocusedWidget,
  updateAlert,
} from '../../adminPageSlice'
import WidgetForm from '../WidgetForm/WidgetForm'
import styles, { StyledDialogContent } from './EditWidgetAdminStyles'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" {...props} ref={ref} />
})

function EditWidgetAdmin() {
  const classes = styles()
  const dispatch = useDispatch()
  const openDialog = useSelector((s) => s.adminPage.editDialogOpen)
  const focusedWidget = useSelector((s) => s.adminPage.focusedWidget)

  const widgets = useSelector((s) => s.adminPage.widgets)

  const [edited, setEdited] = React.useState(false)
  const [widget, setWidget] = React.useState({ title: '' })
  const [title, setTitle] = React.useState('')

  React.useEffect(() => {
    if (widgets[focusedWidget]) {
      setTitle(widgets[focusedWidget].title)
      setWidget(widgets[focusedWidget])
    }
  }, [focusedWidget, widgets])

  const getWidgetIndex = (widgetId) =>
    widgets.findIndex((w) => parseInt(w.id, 10) === parseInt(widgetId, 10))

  const handleEditWidgetSubmit = () => {
    const widgetIndex = getWidgetIndex(widget.id)
    const newList = [...widgets]
    newList[widgetIndex] = { ...widget, manuallyMoved: Date.now() }
    dispatch(addEditRemoveWidgetsInFireStore(newList))
    setEdited(false)
    const msg = `Successfully edited ${widget.title}`
    showAlert(msg)
    handleCancel(true)
  }

  const handleDeleteWidgetSubmit = () => {
    if (
      window.confirm(
        'Are you sure you want to delete this widget? This action is irreversible',
      ) === true
    ) {
      deleteWidget()
    } else {
      console.log('Cancelled Delete')
    }
  }

  const deleteWidget = () => {
    const widgetIndex = getWidgetIndex(widget.id)
    const newList = [...widgets]
    newList.splice(widgetIndex, 1)
    dispatch(addEditRemoveWidgetsInFireStore(newList))
    dispatch(setEditDialog(false))
    const msg = `Successfully deleted ${widget.title}`
    showAlert(msg)
  }

  const showAlert = (msg) => {
    const alert = {
      showAlert: true,
      message: msg,
    }
    dispatch(updateAlert(alert))
  }

  const handleCancel = (forceClose) => {
    if (!edited || forceClose) {
      dispatch(setFocusedWidget(null))
      dispatch(setEditDialog(false))
    }
  }

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={openDialog}
      onClose={() => handleCancel(false)}
      TransitionComponent={Transition}
    >
      <DialogTitle style={{ backgroundColor: '#f5f5f5' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className={classes.headerText}>
              {' '}
              {widget && title}{' '}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.headerText}>
              {' '}
              ID: {widget && widget.id}{' '}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <StyledDialogContent className={classes.dialog}>
        <WidgetForm
          focusedWidget={widget}
          setWidget={setWidget}
          setEdited={setEdited}
        />
      </StyledDialogContent>
      <DialogActions style={{ backgroundColor: '#f5f5f5' }}>
        <Grid container directin="row" justifyContent="space-between">
          <Grid item>
            <Button
              onClick={() => handleDeleteWidgetSubmit()}
              variant="contained"
              color="primary"
            >
              Delete
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{ marginRight: '8px' }}
              onClick={() => handleCancel(true)}
              variant="outlined"
              color="primary"
            >
              {edited ? 'Cancel' : 'Close'}
            </Button>
            <Button
              onClick={() => handleEditWidgetSubmit()}
              variant="contained"
              disabled={!edited}
              color="primary"
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default EditWidgetAdmin
