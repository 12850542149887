import AppsIcon from '@material-ui/icons/Apps'
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus'
// import MenuIcon from '@material-ui/icons/Menu'
import DashboardIcon from '@material-ui/icons/Dashboard'
// import AddCircle from '@material-ui/icons/AddCircle'
import NewsIcon from '@material-ui/icons/ChromeReaderMode'
import SettingsIcon from '@material-ui/icons/Settings'
import Apps from '../../features/apps/Apps'
import Dashboard from '../../features/dashboard/Dashboard'
import Maps from '../../features/maps/MyMap'
import News from '../../features/news/News'
import Settings from '../../features/settings/Settings'
// import ShowSensitiveInfoSideNav from './customButtons/ShowSensitiveInfoSideNav'
// import AddWidgets from './customButtons/AddWidgets'
import { IconDashboard } from './customIcons/IconDashboard'
import { IconApps } from './customIcons/IconApps'
import { IconCommunity } from './customIcons/IconCommunity'
import { IconNews } from './customIcons/IconNews'
import { IconBus } from './customIcons/IconBus'
import { IconSettings } from './customIcons/IconSettings'
import ReorderWidgetsAdmin from '../../features/admin/components/ReorderWidgetsAdmin/ReorderWidgetsAdmin'
// import ReorderWidgets from './customButtons/ReorderWidgets'
import AddWidgetAdmin from '../../features/admin/components/AddWidgetsAdmin/AddWidgetAdmin'

export const navigation = {
  routes: [
    {
      path: '/',
      title: 'Dashboard',
      component: Dashboard,
      Icon: DashboardIcon,
      SvgPath: IconDashboard,
      subPages: [],
      subCategories: [
        {
          customComponent: AddWidgetAdmin,
          title: 'Create A New Widget',
          Icon: null,
          path: '',
          requiresAdmin: true,
        },
        {
          customComponent: ReorderWidgetsAdmin,
          title: 'Reorder Widgets',
          Icon: null,
          path: '',
          requiresAdmin: true,
        },
      ],
      exact: true,
    },
    {
      path: '/apps',
      title: 'Apps',
      component: Apps,
      Icon: AppsIcon,
      SvgPath: IconApps,
      subCategories: null,
      subPages: ['/ViewAppInfoPage', '/infoPage'],
    },
    {
      path: process.env.REACT_APP_MYCOMMUNITY_URL,
      title: 'Community',
      component: Maps,
      Icon: DirectionsBusIcon,
      SvgPath: IconCommunity,
      subCategories: null,
      subPages: [],
    },
    {
      path: '/news',
      title: 'News',
      component: News,
      Icon: NewsIcon,
      SvgPath: IconNews,
      subPages: ['/ViewArticlePage'],
    },
    {
      path: '/maps',
      title: 'Maps',
      component: Maps,
      Icon: DirectionsBusIcon,
      SvgPath: IconBus,
      subCategories: null,
      subPages: [],
    },
    {
      path: '/settings',
      title: 'Settings',
      component: Settings,
      Icon: SettingsIcon,
      SvgPath: IconSettings,
      subCategories: null,
      subPages: [],
    },
  ],
}
