export const serverDataParams = Object.freeze({
  CANCELED_EVENT: 'Canceled',
  UNKNOWN_EVENT: 'UNKNOWN',
})

export const eventTypes = Object.freeze({
  VIRTUAL_EVENT: 'Virtual Event',
  ONLINE_EVENT: 'Online',
  ZOOM: 'zoom',
})

export const eventSatus = Object.freeze({
  CANCELED_EVENT: 'Canceled',
  CANCELED_EVENT_TEXT: 'This event has been canceled',
})

export const mobileEventWrapperBadges = Object.freeze({
  ONGOINGEVENT: 'Ongoing Event',
  ONLINE: 'online',
  PERKS: 'perks',
})
