import React from 'react'

import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import styles from './InlineSocialMediaIconStyles'
import { logDashboardEvent } from '../../events'

function InlineSocialMediaIcons({ buttons, color = null, widgetId, dispatch }) {
  const classes = styles()
  const handleButtonClick = (button) => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        button?.iconLabel,
        button?.link,
      )
    }
    window.open(button?.link, '_blank')
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      {buttons.map((button) => (
        <Tooltip
          title={button.iconLabel}
          key={button?.link + button.iconLabel}
          placement="top"
          arrow
        >
          <IconButton
            onClick={() => handleButtonClick(button)}
            className={color ? null : classes.button}
            label={button.iconLabel}
          >
            <IconPicker iconName={button?.icon} sz="lg" color={color} />
          </IconButton>
        </Tooltip>
      ))}
    </Grid>
  )
}

export default InlineSocialMediaIcons
