import { makeStyles } from '@material-ui/core/styles'

export const bottomSheetBuildingStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    width: '80%',
    marginLeft: '10%',
    bottom: 0,
    borderStartEndRadius: 32,
    borderTopLeftRadius: 32,
    background: theme.palette.background.paper,
    [theme.breakpoints.down(550)]: {
      width: '100%',
      height: 150,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 2,
  },
  appBarIcon: {
    fontSize: 30,
  },
  icon: {
    color: '#cc0033',
    fontSize: 40,
    [theme.breakpoints.down(550)]: {
      fontSize: 30,
    },
  },
  backIcon: {
    color: '#cc0033',
    fontSize: 28,
    marginRight: 4,
  },
  backButtonContainer: {
    marginLeft: 24,
  },
  fillerContainer: {
    marginRight: 24,
  },
  backButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 24,
    marginBottom: 24,
    paddingLeft: 90,
    [theme.breakpoints.down(550)]: {
      paddingLeft: 0,
    },
  },
  img: {
    maxHeight: 260,
    objectFit: 'cover',
    overflow: 'hidden',
    maxWidth: '100%',
    borderRadius: 8,
    [theme.breakpoints.down(550)]: {
      width: 290,
    },
  },
  descriptionContainer: {
    marginTop: 24,
    paddingLeft: 24,
    [theme.breakpoints.down(550)]: {
      marginTop: 30,
      paddingLeft: 50,
      width: '100%',
    },
  },
  buildingNameTextHeader: {
    fontSize: 28,
    fontWeight: 'bold',
    marginRight: 8,
    [theme.breakpoints.down(550)]: {
      fontSize: 14,
    },
  },
  buildingNameText: {
    fontSize: 26,
    [theme.breakpoints.down(550)]: {
      fontSize: 14,
    },
  },
  addressText: {
    fontSize: 20,
    [theme.breakpoints.down(550)]: {
      fontSize: 14,
    },
  },
  bottomButtonRow: {
    marginTop: 100,
    [theme.breakpoints.down(550)]: {
      marginTop: 10,
    },
  },
  restaurantNearText: {
    fontSize: 26,
    fontWeight: 'bold',
    marginLeft: 20,
    [theme.breakpoints.down(550)]: {
      fontSize: 16,
    },
  },
}))
