import { makeStyles } from '@material-ui/core/styles'

export const settingsStyles = makeStyles((theme) => ({
  centerPage: {
    padding: '55px 0px 0px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '55px 0px 0px 0px',
    },
  },
  containPage: {
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'calc(100% - 56px)',
    },
  },
  container: {
    margin: '25px auto',
    width: '60%',
    maxWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      maxWidth: '90vw',
    },
  },
  divider: {
    margin: '20px 0',
  },
  settingsNav: {
    width: '100%',
    height: '100%',
    backgroundColor: '#f2f3f4',
    padding: '10px 0 0 0',
  },
  contentPadding: {
    paddingLeft: '20px',
  },
  helpDeskListItem: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  feedbackFormContainer: {
    paddingRight: '55px',
  },
  feedbackFormButtonContainer: {
    textAlign: 'center',
    margin: '16px 0',
  },
  settingsNavItemIcon: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 40,
    },
  },
  fullWidth: {
    width: '100%',
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
      backgroundColor: theme.palette.action.focus,
    },
  },
  linksCard: {
    margin: '10px 0px',
    width: '100%',
    borderRadius: '10px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    backgroundColor: theme.palette.backgroundColor.secondary,
  },
  supportHelpDeskCard: {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    backgroundColor: theme.palette.backgroundColor.secondary,
  },
  supportListItem: {
    justifyContent: 'center',
    padding: 3,
  },
  supportInfoItem: {
    fontSize: '13px',
  },
  linkGrid: {
    padding: '10px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  sectionTitle: {
    marginTop: '20px',
    marginBottom: '5px',
    width: '100%',
  },
  avatar: {
    width: '60px',
    height: '60px',
  },
  avatarGrid: {
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
      backgroundColor: theme.palette.action.focus,
      borderRadius: '100%',
    },
  },
  box: {
    margin: '10px 0px',
    width: '100%',
  },
  creditsCard: {
    padding: '15px 5px',
  },
  main: {
    backgroundColor: theme.palette.backgroundColor.main,
    paddingBottom: 'calc(100vh - 100%)',
  },
  idDisclaimer: {
    color: theme.palette.base.red,
    fontWeight: 600,
  },
  idCardContainer: {
    marginBottom: 20,
    borderRadius: '10px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    backgroundColor: theme.palette.backgroundColor.secondary,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  idCardContainerForStudent: {
    display: 'flex',
    marginBottom: 20,
    borderRadius: '10px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    backgroundColor: theme.palette.backgroundColor.secondary,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 300,
      height: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: 265,
      height: 355,
    },
    [theme.breakpoints.down('sm')]: {
      height: 355,
    },
  },
  idCardShieldForStudent: {
    height: 'auto',
    width: '80%',
    marginTop: 15,
    marginLeft: 15,
  },
  idCardTop: {
    height: 'auto',
    background: theme.color.rutgersRed,
    borderBottom: '0.5rem solid black',
  },
  idCardBottom: {
    height: '100%',
  },
  idCardShield: {
    height: 'auto',
    width: '50%',
    marginTop: 15,
    marginLeft: 15,
  },
  idCardName: {
    paddingTop: 10,
    marginLeft: 20,
    fontWeight: 'bold',
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  idCardDesignation: {
    paddingTop: 5,
    marginLeft: 20,
    fontWeight: 'bold',
    fontSize: '25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  idProfilePictureImage: {
    width: 150,
    height: 150,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    objectFit: 'cover',
    padding: 10,
  },
  copyrightCard: {
    padding: 20,
  },
  contentCard: {
    backgroundColor: '#424242',
  },
  moreInfoButon: {
    borderRadius: 5,
    width: '100%',
    height: 50,
    marginBottom: 15,
  },
  pageToggleChip: {
    background: theme.color.rutgersRed,
    color: theme.palette.base.white,
    '&:hover': {
      background: `${theme.color.rutgersRed} !important`,
      color: theme.palette.base.white,
    },
  },
  idCardGuestContainer: {
    marginBottom: 20,
    borderRadius: '10px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    backgroundColor: theme.palette.base.white,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  idCardGuestTop: {
    height: 'auto',
    background: theme.palette.base.white,
    borderBottom: '0.5rem solid #cc0033',
  },
  idProfilePictureImageForSettings: {
    width: 100,
    height: 100,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    objectFit: 'cover',
    padding: 10,
  },
}))

export default settingsStyles
