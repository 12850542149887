/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchITSMAnnouncements = createAsyncThunk(
  'fetchITSMAnnouncements',
  async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_ITSTAFF_ANNOUNCEMENTS_ENDPOINT}`,
    )
    return res.data
  },
)

export const fetchITSMCases = createAsyncThunk('fetchITSMCases', async () => {
  const jwtToken = localStorage.getItem('myrJwtToken')
  const rcpid = localStorage.getItem('rcpid')
  return axios.all([
    axios.get(`${process.env.REACT_APP_ITSTAFF_OPENCASES_ENDPOINT}`, {
      headers: {
        JWT: jwtToken,
        XWT: rcpid,
      },
    }),
    axios.get(`${process.env.REACT_APP_ITSTAFF_CLOSEDCASES_ENDPOINT}`, {
      headers: {
        JWT: jwtToken,
        XWT: rcpid,
      },
    }),
  ])
})

const initialState = {
  status: '',
  caseStatus: '',
  myCasesOpen: [],
  myCasesClosed: [],
  announcements: [],
  value: 0,
  value2: 0,
  query: '',
}

const itServiceCenterSlice = createSlice({
  name: 'itServiceCenter',
  initialState,
  reducers: {
    updateTabValue: (state, action) => {
      if (state.value !== action.payload) {
        state.value = action.payload
      }
    },
    updateTabValue2: (state, action) => {
      if (state.value2 !== action.payload) {
        state.value2 = action.payload
        // state.caseStatus = 'pending'
      }
      return state
    },
    updateQuery: (state, action) => {
      state.query = action.payload.target.value
    },
  },
  extraReducers: {
    [fetchITSMAnnouncements.pending]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [fetchITSMAnnouncements.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.announcements = action.payload
    },
    [fetchITSMAnnouncements.rejected]: (state) => ({
      ...state,
      status: 'error',
    }),
    [fetchITSMCases.pending]: (state) => ({
      ...state,
      caseStatus: 'pending',
    }),
    [fetchITSMCases.fulfilled]: (state, action) => {
      state.caseStatus = 'fulfilled'
      state.ruITLightImage = action.payload[0]?.data?.ruITLightImage
      state.ruITDarkImage = action.payload[0]?.data?.ruITDarkImage
      state.myCasesOpen = action.payload[0]?.data
      state.myCasesClosed = action.payload[1]?.data
    },
    [fetchITSMCases.rejected]: (state) => ({
      ...state,
      caseStatus: 'error',
    }),
  },
})

export const { updateTabValue, updateTabValue2, updateQuery } =
  itServiceCenterSlice.actions

export default itServiceCenterSlice.reducer
