/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Grid, Box, Link, Typography as Text } from '@material-ui/core'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { RuDotsServiceCenterStyles } from './RUDOTSServiceCenterStyles'
import {
  TabPanel,
  a11yProps,
} from '../../DashboardComponents/TabPanel/TabPanel'
import Widget, { SmallIconLink } from '../Widget'
import SearchBar from '../../DashboardComponents/SearchBar/SearchBar'
import Footer from '../../DashboardComponents/Footer/Footer'
import { logDashboardEvent } from '../../events'

import MyCasesObject from './MyCasesObject'
import Announcement from './Announcement'
import {
  fetchParkingAnnouncements,
  fetchParkingCases,
  updateQuery,
  updateTabValue,
  updateTabValue2,
} from './RUDOTSServiceCenterSlice'
import Loading from '../helpers/Loading'
import {
  RutgersTab,
  RutgersTabs,
} from '../../DashboardComponents/Tabs/RutgersTabs'
import CaseIndicator from '../../DashboardComponents/CaseIndicator/CaseIndicator'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
import { getImageFromFirebaseWithHook } from '../../../apps/helpers'

/**
 * RU DOTS Service Widget
 * Shows announcements and cases for the account as well as redirect links
 * @param {*} param0
 * @returns
 */
// eslint-disable-next-line no-unused-vars
export default function RUDOTSServiceCenter({ className, title, widgetId }) {
  const classes = RuDotsServiceCenterStyles()
  const dispatch = useDispatch()
  const { announcements, status } = useSelector((s) => s.RUDOTSServiceCenter)
  React.useEffect(() => {
    dispatch(fetchParkingAnnouncements())
    dispatch(fetchParkingCases())
  }, [])
  return (
    <Widget className={className}>
      <Widget.Header title={title} />
      <Widget.Content className={classes.content}>
        {renderRUDOTSServiceState(announcements, status, widgetId)}
      </Widget.Content>
    </Widget>
  )
}

function renderRUDOTSServiceState(announcements, status, widgetId) {
  switch (status) {
    case 'fulfilled':
      return <Loaded announcements={announcements} widgetId={widgetId} />
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}
function renderOpenCaseState(caseStatus, widgetId, dispatch) {
  switch (caseStatus) {
    case 'fulfilled':
      return <MyOpenCases widgetId={widgetId} dispatch={dispatch} />
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}
function renderClosedCaseState(caseStatus, widgetId, dispatch) {
  switch (caseStatus) {
    case 'fulfilled':
      return <MyClosedCases widgetId={widgetId} dispatch={dispatch} />
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded(props) {
  const [image, setImg] = React.useState(null)
  const { announcements, widgetId } = props
  const classes = RuDotsServiceCenterStyles()
  const dispatch = useDispatch()
  const settingsObj = useSelector((s) => s.settings.data)
  const { darkMode } = settingsObj
  const handleChange = (event, newValue) => {
    dispatch(updateTabValue(newValue))
  }
  const handleChange2 = (event, newValue2) => {
    dispatch(updateTabValue2(newValue2))
  }

  const {
    myCasesOpen,
    value,
    value2,
    query,
    caseStatus,
    ruDotsLightImage,
    ruDotsDarkImage,
  } = useSelector((s) => s.RUDOTSServiceCenter)

  const imageLocation = darkMode ? ruDotsDarkImage : ruDotsLightImage

  React.useEffect(() => {
    if (imageLocation) {
      getImageFromFirebaseWithHook(imageLocation, setImg)
    }
  }, [imageLocation])

  React.useEffect(() => {
    if (value === 1) {
      dispatch(fetchParkingCases())
    }
  }, [value])

  const searchOnRequest = (val) => {
    // Spaces are replaced by '%20' in the search link
    const redirectString = encodeURIComponent(
      val.toString().split(' ').join('%20'),
    )
    const link = `${process.env.REACT_APP_RUDOTSSERVICE_BASE_URL}/ru_dots?id=search&spa=1&q=${redirectString}`

    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        redirectString,
        link,
      )
    }
    // Opens new tab with search bar value
    window.open(link, '_blank')
  }

  const footerButtonTitle = 'Launch Department of Transportation Service Center'
  const footerLink = `${process.env.REACT_APP_RUDOTSSERVICE_BASE_URL}/ru_dots`
  const handleFooterLinkClick = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        footerButtonTitle,
        footerLink,
      )
    }
  }

  return (
    <Grid
      container
      direction="column"
      className={classes.mainGrid}
      wrap="nowrap"
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item className={classes.imgContainer} container direction="row">
          <img className={classes.img} alt="RUDOTSHelp" src={image} />
        </Grid>

        <Grid item className={classes.searchBar}>
          <SearchBar
            placeholder="How can we help you?"
            className={classes.searchBar}
            value={query}
            onChange={(result) => dispatch(updateQuery(result))}
            onCancelSearch={() =>
              dispatch(updateQuery({ target: { value: '' } }))
            }
            webViewMode
            onRequestSearch={() => searchOnRequest(query)}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Grid className={classes.modalTabs}>
        <RutgersTabs value={value} onChange={handleChange} aria-label="Top Tab">
          <RutgersTab label="Announcements" {...a11yProps(0)} />
          <RutgersTab
            label={
              <Box display="flex">
                <Text className={classes.tabTextLabel}>My Cases</Text>
                {myCasesOpen && myCasesOpen.length > 0 && (
                  <CaseIndicator value={myCasesOpen?.length} />
                )}
              </Box>
            }
            {...a11yProps(1)}
          />
        </RutgersTabs>
      </Grid>
      <Grid item className={classes.scrollContent}>
        <TabPanel value={value} index={0}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <div className={classes.announcementList}>
              {announcements?.results?.length === 0 ? (
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <CheckCircleOutlineIcon className={classes.circleIcon} />
                  </Grid>

                  <Grid item>
                    <Text variant="subtitle1">No Announcement Data</Text>
                  </Grid>
                </Grid>
              ) : (
                <>
                  {announcements?.results?.map((announcement, index) => (
                    <Announcement
                      key={index}
                      announcement={announcement}
                      widgetId={widgetId}
                      dispatch={dispatch}
                    />
                  ))}
                </>
              )}
            </div>
            <div className={classes.announcementList}>
              {announcements?.length === 0 ? (
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <CheckCircleOutlineIcon className={classes.circleIcon} />
                  </Grid>

                  <Grid item>
                    <Text variant="subtitle1">No Announcement Data</Text>
                  </Grid>
                </Grid>
              ) : null}
            </div>
          </Grid>
        </TabPanel>

        {/* My cases tab */}
        <TabPanel value={value} index={1}>
          <Box className={classes.modalTabs}>
            <RutgersTabs
              value={value2}
              onChange={handleChange2}
              aria-label="Top Tab"
            >
              <RutgersTab label="Open" {...a11yProps(2)} />
              <RutgersTab label="Closed" {...a11yProps(3)} />
            </RutgersTabs>
          </Box>

          {/* Open tab */}
          <TabPanel value={value2} index={0}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <div className={classes.casesList}>
                {renderOpenCaseState(caseStatus, widgetId, dispatch)}
              </div>
            </Grid>
          </TabPanel>

          {/* Closed tab */}
          <TabPanel value={value2} index={1}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <div className={classes.casesList}>
                {renderClosedCaseState(caseStatus, widgetId, dispatch)}
              </div>
            </Grid>
          </TabPanel>
        </TabPanel>
      </Grid>
      {/* Bottom container */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
        className={classes.bottomContainer}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          className={classes.grid1}
        >
          <Grid item>
            <SmallIconLink
              classes={classes}
              icon="fas fa-book-open"
              iconLabel={
                <Text component="span" className={classes.tabLink}>
                  Find Answers
                </Text>
              }
              link={`${process.env.REACT_APP_RUDOTSSERVICE_BASE_URL}/ru_dots?id=kb_view2`}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </Grid>

          <Grid item>
            <SmallIconLink
              classes={classes}
              icon="fas fa-book"
              iconLabel={
                <Text component="span" className={classes.tabLink}>
                  Request Forms
                </Text>
              }
              link={`${process.env.REACT_APP_RUDOTSSERVICE_BASE_URL}/ru_dots?id=sc_category`}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </Grid>

          <Grid item>
            <SmallIconLink
              classes={classes}
              icon="fas fa-question"
              iconLabel={
                <Text component="span" className={classes.tabLink}>
                  Get Help
                </Text>
              }
              link={`${process.env.REACT_APP_RUDOTSSERVICE_BASE_URL}/ru_dots?id=dots_get_help&sys_id=0ac4a5dbdb65fc105a54230bd3961949`}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </Grid>
        </Grid>
        <Footer>
          <Text variant="subtitle1" className={classes.footerText}>
            <Link
              href={footerLink}
              target="_blank"
              underline="none"
              color="secondary"
              onClick={() => handleFooterLinkClick()}
            >
              {footerButtonTitle}
            </Link>
          </Text>
        </Footer>
      </Grid>
    </Grid>
  )
}

function MyOpenCases({ widgetId, dispatch }) {
  const classes = RuDotsServiceCenterStyles()
  const { myCasesOpen } = useSelector((s) => s.RUDOTSServiceCenter)
  return (
    <>
      {myCasesOpen && myCasesOpen.length === 0 ? (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <CheckCircleOutlineIcon className={classes.circleIcon} />
          </Grid>

          <Grid item>
            <Text variant="subtitle1">No Open Case Data</Text>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="column">
          <>
            {myCasesOpen?.map((cases, index) => (
              <MyCasesObject
                key={index}
                cases={cases}
                widgetId={widgetId}
                dispatch={dispatch}
              />
            ))}
          </>
        </Grid>
      )}
    </>
  )
}

function MyClosedCases({ widgetId, dispatch }) {
  const classes = RuDotsServiceCenterStyles()
  const { myCasesClosed } = useSelector((s) => s.RUDOTSServiceCenter)
  return (
    <>
      {myCasesClosed.length === 0 ? (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <CheckCircleOutlineIcon className={classes.circleIcon} />
          </Grid>

          <Grid item>
            <Text variant="subtitle1">No Closed Case Data</Text>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="column">
          <>
            {myCasesClosed.map((cases, index) => (
              <MyCasesObject
                key={index}
                cases={cases}
                status="closed"
                widgetId={widgetId}
                dispatch={dispatch}
              />
            ))}
          </>
        </Grid>
      )}
    </>
  )
}
