import React from 'react'
import DOMPurify from 'dompurify'
import { Typography as Text, Grid, Card } from '@material-ui/core'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useDispatch, useSelector } from 'react-redux'
import { formatUSD } from '../../../../../utils/format'
import Widget from '../Widget'
import myMoneyStyles from './myMoneyStyles'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
import {
  fetchMyMoney,
  selectMoneyPaymentDue,
  selectPaycheckStatus,
  // selectHiddenEyeButton,
  //   selectPaymentNotice,
  selectIconLinks,
  selectTab,
  updateTab,
} from './myMoneySlice'
import {
  RutgersTabs,
  RutgersTab,
} from '../../DashboardComponents/Tabs/RutgersTabs'
import { a11yProps } from '../../DashboardComponents/TabPanel/TabPanel'
import IconLinks from '../../DashboardComponents/IconLinks/IconLinks'
import Loading from '../helpers/Loading'
import HideShowButton from '../../DashboardComponents/HideShowButton/HideShowButton'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'

const DATE_FORMAT = 'ddd MMM D, YYYY'

// TODO Fix chip so that it uses less custom color props
// eslint-disable-next-line no-unused-vars
function MyMoney({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const widgetCallState = useSelector(selectPaycheckStatus)
  const classes = myMoneyStyles()

  const moneyPaymentDue = useSelector(selectMoneyPaymentDue)
  const [isBoth, setIsBoth] = React.useState(false)
  React.useEffect(() => {
    dispatch(fetchMyMoney()).then((responseData) => {
      if (
        responseData.payload?.rbhs &&
        Object.keys(responseData?.payload?.rbhs).length > 0
      ) {
        dispatch(updateTab('rbhs'))
      }
      if (
        responseData?.payload?.rbhs &&
        Object.keys(responseData?.payload?.rbhs).length > 0 &&
        responseData?.payload?.ru &&
        Object.keys(responseData?.payload?.ru).length > 0
      ) {
        setIsBoth(true)
      }
    })
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        className={classes.content}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {myMoneyState(
          widgetCallState,
          widgetId,
          dispatch,
          moneyPaymentDue,
          isBoth,
        )}
      </Widget.Content>
    </Widget>
  )
}

function myMoneyState(
  widgetCallState,
  widgetId,
  dispatch,
  moneyPaymentDue,
  isBoth,
) {
  const classes = myMoneyStyles()
  // TODO: Handle Proper Loading and Error States
  switch (widgetCallState) {
    case 'fulfilled':
      return (
        <Loaded
          moneyPaymentDue={moneyPaymentDue}
          widgetId={widgetId}
          dispatch={dispatch}
          isBoth={isBoth}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.text}></Grid>
        </Grid>
      )
  }
}

function Loaded(props) {
  const classes = myMoneyStyles()
  const tab = useSelector(selectTab)
  const iconLinks = useSelector(selectIconLinks)
  //   const paymentNotice = useSelector(selectPaymentNotice)
  //   const hiddenEyeButton = useSelector(selectHiddenEyeButton)
  const { data } = useSelector((s) => s.myMoney)
  const paymentValue = parseFloat(data[tab]?.flatHiddenEyeButton?.hiddenValue)
  dayjs.extend(customParseFormat)
  const handleChange = (e, newValue) => {
    props.dispatch(updateTab(newValue))
    // props.dispatch(fetchMyMoney())
  }
  const handleBalanceFormat = () => {
    // handle the case where the balance is "Not released yet"
    const balance = formatUSD(paymentValue)
    if (balance === `$NaN`) {
      return data[tab]?.flatHiddenEyeButton?.hiddenValue
    }
    return balance
  }

  const handlePaymentFormat = () => {
    const paymentDate = dayjs(data[tab]?.paymentDue, 'MM-DD-YYYY').format(
      DATE_FORMAT,
    )
    if (paymentDate === `Invalid Date`) {
      return `N/A`
    }
    return paymentDate
  }

  const handleCreditHoursFormat = () => {
    const creditHours = Number(data[tab]?.billableCreditHrs) / 10
    if (Number.isNaN(creditHours)) {
      return 'N/A'
    }
    return creditHours
  }
  const additionalVoiceOver =
    props.color === 'green'
      ? `You're all caught up!`
      : `Your remaining bill is ${
          data[tab]?.flatHiddenEyeButton?.hiddenValue
            ? formatUSD(paymentValue)
            : 'N/A'
        }`
  return (
    <div>
      {props.isBoth === true ? (
        <RutgersTabs value={tab} onChange={handleChange} aria-label="Top Tab">
          <RutgersTab label="RU" value="ru" {...a11yProps(0)} />
          <RutgersTab label="RBHS" value="rbhs" {...a11yProps(1)} />
        </RutgersTabs>
      ) : null}
      <Widget.Spacer />
      {data?.ruHolds ? (
        <>
          {data?.ruHolds?.map((hold) => (
            <Grid item className={classes.gridMainStyle}>
              <Card className={classes.award} variant="outlined">
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <IconPicker
                      iconName={hold?.icon?.icon || 'exclamation-circle'}
                      color={hold?.icon?.color || '#cc0033'}
                      size="5x"
                    ></IconPicker>
                  </Grid>
                  <Grid item>
                    <Text
                      className={classes.titleStyle}
                      variant="body1"
                      style={{ marginTop: '10px', marginBottom: '5px' }}
                    >
                      {hold.title}
                    </Text>
                  </Grid>
                  <Grid item>
                    <Text variant="body1">
                      {hold.message
                        .split('https://personalinfo.rutgers.edu.')
                        .map((segment, index, array) => {
                          const key = `segment_${index}_${segment}`
                          return (
                            <React.Fragment key={key}>
                              {segment}
                              {index !== array.length - 1 && (
                                <u>
                                  <a
                                    href="https://personalinfo.rutgers.edu"
                                    className={classes.textStyle}
                                  >
                                    https://personalinfo.rutgers.edu.
                                  </a>
                                </u>
                              )}
                            </React.Fragment>
                          )
                        })}
                    </Text>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </>
      ) : (
        <>
          <HideShowButton
            displayData={
              data[tab]?.flatHiddenEyeButton?.hiddenValue
                ? handleBalanceFormat()
                : 'N/A'
            }
            color={data[tab]?.flatHiddenEyeButton?.buttonColor}
            textColor={data[tab]?.flatHiddenEyeButton?.iconColor}
            widgetId={props.widgetId}
            dispatch={props.dispatch}
            moduleName="Bill"
            additionalVoiceOver={additionalVoiceOver}
          />

          <Widget.Spacer />

          <Text variant="subtitle1">
            Payment Due:{' '}
            <Text
              className={classes.placeholder}
              variant="subtitle1"
              color="textSecondary"
              component="span"
              display="inline"
            >
              {data[tab]?.paymentDue ? handlePaymentFormat() : 'N/A'}
            </Text>
          </Text>
          <Text variant="subtitle1">
            Billable Credit Hours:{' '}
            <Text
              className={classes.placeholder}
              variant="subtitle1"
              color="textSecondary"
              component="span"
              display="inline"
            >
              {data[tab]?.billableCreditHrs ? handleCreditHoursFormat() : 'N/A'}
            </Text>
          </Text>
          <Widget.Spacer />
          <Text variant="caption">
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data[tab]?.paymentNotice, {
                  ADD_ATTR: ['target'],
                }),
              }}
            />
          </Text>
          <Widget.Spacer />
          <IconLinks
            icons={iconLinks}
            widgetId={props.widgetId}
            dispatch={props.dispatch}
            perRow={3}
          />
        </>
      )}
    </div>
  )
}

export default MyMoney
