import * as React from 'react'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { eventCardStyles } from './eventCardStyles'
import { mobileEventWrapperBadges } from './eventCardParams'

const OngoingeventBadge = ({ isOngoing, isMobile }) => {
  const classes = eventCardStyles({ isMobile })
  return isOngoing ? (
    <Typography component="div" className={classes.eventCardOngoingBadge}>
      {mobileEventWrapperBadges.ONGOINGEVENT}
    </Typography>
  ) : null
}

OngoingeventBadge.propTypes = {
  isOngoing: PropTypes.bool,
  isMobile: PropTypes.bool,
}
export default OngoingeventBadge
