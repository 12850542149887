/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { getImageFromFirebaseWithoutHook } from '../../../apps/helpers'

export const fetchCommuterResources = createAsyncThunk(
  'fetchCommuterResources',
  async () => {
    const res = await axios.get(
      process.env.REACT_APP_COMMUTERRESOURCES_ENDPOINT,
    )
    return res.data
  },
)

export const fetchCommuterResourcesImage = createAsyncThunk(
  'fetchCommuterResourcesImage',
  async (params) => {
    const res = await getImageFromFirebaseWithoutHook(params.image)
    return res
  },
)

export const fetchCommuterResourcesEvents = createAsyncThunk(
  'fetchCommuterResourcesEvents',
  async () => {
    const res = await axios.get(
      process.env.REACT_APP_COMMUTERRESOURCESEVENTS_ENDPOINT,
      {
        headers: {
          JWT: 'F5fy27znno5G8LAJTwiMS6AYo9BYeU',
          XWT: 0,
        },
      },
    )
    return res.data
  },
)

// This is the initial state object the reducers and extraReducers will change based on whatever we need to change it to. It is required when creating the createSlice object.
const initialState = {
  status: 'idle',
  imageStatus: 'idle',
  eventsStatus: 'idle',
  data: {
    title: 'Commuter Resources',
    amenities: {},
    checklist: {},
    events: {},
    eventsList: [],
    contact: {},
    footer: { inlineSocialMediaIcons: [null, null, null, null] },
  },
}

const commuterResourceSlice = createSlice({
  name: 'commuterResourceWidget',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCommuterResources.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchCommuterResources.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchCommuterResources.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data.title = 'Commuter Resources'
      // AMENITIES
      const amenities = { title: action.payload.amenitiesTabTitle, lounges: [] }
      action.payload.amenitiesLoungeTitles?.forEach((title, index) => {
        const camelcaseName = title.replace(/ /g, '') // remove spaces
        const singleLounge = {
          title,
          subtitle: action.payload.amenitiesLoungeSubtitles[index],
          image: action.payload.amenitiesLoungeImages[index],
          imageFinal: '', // loads when fetchCommuterResourcesImage is called
          imageAltText: action.payload.amenitiesLoungeImagesAltText[index],
          amenitiesList: action.payload[`amenitiesLounge${camelcaseName}Items`],
          listHeaderText: action.payload.amenitiesLoungeListHelperText,
        }
        amenities.lounges.push(singleLounge)
      })
      state.data.amenities = amenities
      // CHECKLIST
      const checklist = []
      action.payload.checklistTitles.forEach((title) => {
        const camelcaseName = title.replace(/ /g, '') // remove spaces
        const singleChecklist = {
          title,
          content: action.payload[`checklist${camelcaseName}DD`],
        }
        checklist.push(singleChecklist)
      })
      state.data.checklist = checklist
      // EVENTS STATIC
      state.data.events = {
        buttonText: action.payload.eventsTabButtonText,
        buttonLink: action.payload.eventsTabButtonLink,
        description: action.payload.eventsTabDesc,
        image: action.payload.eventsTabImage,
        imageFinal: '', // loads when fetchCommuterResourcesImage is called
        imageAltText: action.payload.eventsTabImageAltText,
        title: action.payload.eventsTabTitle,
      }
      // CONTACT
      const contact = []
      action.payload.contactTitles.forEach((title, index) => {
        const camelcaseName = title.replace(/ /g, '') // remove spaces
        const singleCard = {
          title,
          subtitle: action.payload.contactSubtitles[index],
          linkTitles: action.payload[`contact${camelcaseName}LinkTitles`],
          linkValues: action.payload[`contact${camelcaseName}Items`],
        }
        contact.push(singleCard)
      })
      state.data.contact = contact
      // ABOUT
      state.data.about = {
        image: action.payload.aboutUsImage,
        imageFinal: '', // loads when fetchCommuterResourcesImage is called
        description: action.payload.aboutUsDesc,
        navText: action.payload.aboutUsNavText,
      }
      // FOOTER
      const inlineSocialMediaIcons = []
      for (let x = 0; x < action.payload?.footerIconsImages?.length; x++) {
        const singleIcon = {
          icon: action.payload.footerIconsImages[x],
          iconFinal: '', // loads when fetchCommuterResourcesImage is called
          iconFamily: action.payload.footerIconsFamilies[x],
          iconLabel: action.payload.footerIconsLabels[x],
          link: action.payload.footerURLs[x],
        }
        inlineSocialMediaIcons.push(singleIcon)
      }
      state.data.footer = {
        buttonText: action.payload.footerButtonText,
        buttonLink: action.payload.footerButtonLink,
        inlineSocialMediaIcons,
      }
    },
    [fetchCommuterResourcesImage.rejected]: (state) => {
      state.imageStatus = 'error'
    },
    [fetchCommuterResourcesImage.pending]: (state) => {
      state.imageStatus = 'pending'
    },
    [fetchCommuterResourcesImage.fulfilled]: (state, action) => {
      state.imageStatus = 'fulfilled'
      switch (action.meta.arg.type) {
        case 'amenities':
          state.data.amenities.lounges[action.meta.arg.index].imageFinal =
            action.payload
          break
        case 'events':
          state.data.events.imageFinal = action.payload
          break
        case 'about':
          state.data.about.imageFinal = action.payload
          break
        case 'social':
          state.data.footer.inlineSocialMediaIcons[
            action.meta.arg.index
          ].iconFinal = action.payload
          break
        default:
          break
      }
    },
    [fetchCommuterResourcesEvents.rejected]: (state) => {
      state.eventsStatus = 'error'
    },
    [fetchCommuterResourcesEvents.pending]: (state) => {
      state.eventsStatus = 'pending'
    },
    [fetchCommuterResourcesEvents.fulfilled]: (state, action) => {
      state.eventsStatus = 'fulfilled'
      state.data.eventsList = action.payload
    },
  },
})
// List of selector functions that can be used to access state inside of the CommuterResources.js Component.
export const selectCRData = (state) => state.commuterResource.data
export const selectCRStatus = (state) => state.commuterResource.status
export const selectCREvents = (state) => state.commuterResource.eventsList

export default commuterResourceSlice.reducer
