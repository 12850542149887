import React from 'react'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { Typography, Grid, ButtonBase } from '@material-ui/core'

import settingsStyles from '../settingsStyles'

function PageLink({ title, description, link, path, icon, action }) {
  const classes = settingsStyles()
  const handleClick = () => {
    if (link) {
      link(path)
    } else if (action) {
      action()
    }
  }
  return (
    <ButtonBase className={classes.fullWidth} onClick={handleClick}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.linkGrid}
      >
        <Grid item xs container direction="column" alignItems="flex-start">
          <Typography variant="h6">{title}</Typography>
          <Typography variant="caption">{description}</Typography>
        </Grid>
        <Grid item>{icon || <ArrowForwardIosIcon tabIndex={-1} />}</Grid>
      </Grid>
    </ButtonBase>
  )
}

export default PageLink
