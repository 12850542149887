import { makeStyles } from '@material-ui/core/styles'

export const ManageNewsStyles = makeStyles((theme) => ({
  appBarIcon: {
    color: theme.palette.components.topBarIcon,
    fontSize: '24px',
  },
  activeTab: {
    color: theme.palette.base.red,
    background: theme.palette.paper.primary,
    minWidth: 30,
    width: 30,
    paddingLeft: 0,
    paddingRight: 0,
  },
  inactiveTab: {
    background: theme.palette.paper.secondary,
    minWidth: 30,
    paddingLeft: 0,
    paddingRight: 0,
  },
  helperText: {
    marginRight: theme.spacing(1),
  },
}))
