import * as React from 'react'
import { doc, collection, getDoc } from 'firebase/firestore'
import Grid from '@material-ui/core/Grid'
import { Card, Typography as Text } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'
import Widget from '../Widget'
import myDegreeStyles from './myDegreeStyles'
import myDegreeTutorial from './myDegreeTutorial'
import Loading from '../helpers/Loading'
import tutorialStyles from '../../DashboardComponents/Tutorial/TutorialStyles'
import TutorialMode from '../../DashboardComponents/Tutorial/TutorialMode'
import HiddenEyeButton from '../../DashboardComponents/HiddenEyeButton/HiddenEyeButton'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

import {
  fetchMyDegree,
  selectMyDegreeDegree,
  selectMyDegreeSchool,
  selectShowDegreeTutorial,
  selectMyDegreeStatus,
  endTutorial,
  selectHiddenEyeButtons,
  selectIconLinks,
  selectMyDegreeDataIncomplete,
  fetchMyDegreeTutorial,
  selectMyDegreeDegreeTutorial,
  selectMyDegreeSchoolTutorial,
  selectHiddenEyeButtonsTutorial,
  selectIconLinksTutorial,
  selectMyDegreeHolds,
} from './myDegreeSlice'
import IconLink from '../../DashboardComponents/IconLinks/IconLink'
import { firestore } from '../../../../../utils/firebase'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
// eslint-disable-next-line no-unused-vars
function MyDegree({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const classes = myDegreeStyles()

  React.useEffect(() => {
    dispatch(fetchMyDegree())
  }, [])

  const widgetCallState = useSelector(selectMyDegreeStatus)

  const degree = useSelector(selectMyDegreeDegree)
  const school = useSelector(selectMyDegreeSchool)
  const holds = useSelector(selectMyDegreeHolds)
  const hiddenEyeButtons = useSelector(selectHiddenEyeButtons)
  const iconLinks = useSelector(selectIconLinks)
  const showTutorial = useSelector(selectShowDegreeTutorial)
  const myDegreeDataIncomplete = useSelector(selectMyDegreeDataIncomplete)
  const stateEndTutorial = useSelector(endTutorial)

  React.useEffect(() => {
    if (showTutorial && myDegreeDataIncomplete)
      dispatch(fetchMyDegreeTutorial())
  }, [showTutorial, myDegreeDataIncomplete])

  const degreeTutorial = useSelector(selectMyDegreeDegreeTutorial)
  const schoolTutorial = useSelector(selectMyDegreeSchoolTutorial)
  const hiddenEyeButtonsTutorial = useSelector(selectHiddenEyeButtonsTutorial)
  const iconLinksTutorial = useSelector(selectIconLinksTutorial)

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        className={classes.degreeContainer}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderMyDegreeState(
          widgetCallState,
          showTutorial && myDegreeDataIncomplete ? degreeTutorial : degree,
          showTutorial && myDegreeDataIncomplete ? schoolTutorial : school,
          showTutorial && myDegreeDataIncomplete
            ? hiddenEyeButtonsTutorial
            : hiddenEyeButtons,
          showTutorial && myDegreeDataIncomplete
            ? iconLinksTutorial
            : iconLinks,
          showTutorial,
          stateEndTutorial,
          dispatch,
          widgetId,
          holds,
        )}
      </Widget.Content>
    </Widget>
  )
}

function renderMyDegreeState(
  widgetCallState,
  degree,
  school,
  hiddenEyeButtons,
  iconLinks,
  showTutorial,
  stateEndTutorial,
  dispatch,
  widgetId,
  holds,
) {
  const classes = myDegreeStyles()

  switch (widgetCallState) {
    case 'fulfilled':
      return (
        <Loaded
          degree={degree}
          school={school}
          holds={holds}
          iconLinks={iconLinks}
          hiddenEyeButtons={hiddenEyeButtons}
          showTutorial={showTutorial}
          stateEndTutorial={stateEndTutorial}
          dispatch={dispatch}
          widgetId={widgetId}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.text}></Grid>
        </Grid>
      )
  }
}

function Loaded({
  degree,
  school,
  holds,
  hiddenEyeButtons,
  iconLinks,
  stateEndTutorial,
  dispatch,
  widgetId,
}) {
  const classes = myDegreeStyles()
  const tutorialClasses = tutorialStyles()
  const tutorial = myDegreeTutorial
  const [showTutorialBool, setShowTutorial] = React.useState(false)
  const emptyHiddenEyeButtons = [
    {
      icon: 'fas fa-eye-slash',
      iconFamily: 'Font Awesome',
      iconLabel: 'Last Semester',
      value: 0.0,
    },
    {
      icon: 'fas fa-eye-slash',
      iconFamily: 'Font Awesome',
      iconLabel: 'Cumulative',
      value: 0.0,
    },
    {
      icon: 'fas fa-eye-slash',
      iconFamily: 'Font Awesome',
      iconLabel: 'Total Degree Credits',
      value: 0.0,
    },
  ]

  React.useEffect(() => {
    const fetchTutorialData = async () => {
      const userDocRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user'),
        'settings',
        'tutorials',
      )
      const docData = await getDoc(userDocRef)
      setShowTutorial(docData.data()?.show_my_degree_tutorial)
    }
    fetchTutorialData()
    return () => setShowTutorial(false)
  }, [])
  if (holds?.length > 0) {
    return (
      <>
        <Grid item style={{ width: '100%' }}>
          <Card
            className={classes.award}
            style={{ textAlign: 'center', alignItems: 'center' }}
            variant="outlined"
          >
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <IconPicker
                  iconName={holds[0].icon.icon}
                  color={holds[0].icon.color}
                  size="5x"
                ></IconPicker>
              </Grid>
              <Grid item>
                <Text
                  className={classes.titleStyle}
                  variant="body1"
                  style={{ marginTop: '10px', marginBottom: '5px' }}
                >
                  {holds[0].title}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="body1">
                  {holds[0].message
                    .split('https://personalinfo.rutgers.edu.')
                    .map((segment, index, array) => {
                      const key = `segment_${index}_${segment}`
                      return (
                        <React.Fragment key={key}>
                          {segment}
                          {index !== array.length - 1 && (
                            <u>
                              <a
                                href="https://personalinfo.rutgers.edu."
                                style={{
                                  fontWeight: 'bold',
                                  color: 'black',
                                }}
                              >
                                https://personalinfo.rutgers.edu.
                              </a>
                            </u>
                          )}
                        </React.Fragment>
                      )
                    })}
                </Text>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </>
    )
  }
  return (
    <>
      <Grid container direction="column" justifyContent="space-around">
        <Grid item>
          <Widget.Spacer />
        </Grid>
        <Grid item>
          <Text variant="subtitle1">{degree ?? 'Degree: N/A'}</Text>

          <Text variant="subtitle2">{school ?? 'School: N/A'}</Text>
        </Grid>

        <Grid item>
          <Grid container direction="column" justifyContent="space-evenly">
            <Grid item>
              <Text variant="subtitle1">GPA</Text>
            </Grid>

            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                wrap="wrap"
              >
                {(hiddenEyeButtons ?? emptyHiddenEyeButtons).map(
                  (hiddenEyeButton, index) => (
                    <Grid
                      item
                      xs={6}
                      className={getColor(index, classes)}
                      key={hiddenEyeButton.value + hiddenEyeButton.iconLabel}
                    >
                      <HiddenEyeButton
                        widgetId={widgetId}
                        dispatch={dispatch}
                        value={hiddenEyeButton.value}
                        label={hiddenEyeButton.iconLabel}
                      />
                    </Grid>
                  ),
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* TO DO Update this to handle widgets and urls from json */}
        <Grid container direction="row" wrap="wrap">
          {iconLinks &&
            iconLinks.map((iconLink) => (
              <Grid
                item
                xs={4}
                className={classes.verticalSpace}
                key={iconLink.iconLabel + iconLink.link}
              >
                <IconLink
                  icon={iconLink.icon}
                  iconLabel={iconLink.iconLabel}
                  link={iconLink.link}
                  widgetId={widgetId}
                  dispatch={dispatch}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      {showTutorialBool === true ? (
        <TutorialMode
          showTutorial={showTutorialBool}
          steps={tutorial}
          styles={tutorialClasses}
          stateEndTutorial={stateEndTutorial}
          dispatch={dispatch}
        />
      ) : (
        false
      )}
    </>
  )
}

function getColor(index, classes) {
  switch (index % 3) {
    case 0:
      return classes.blueIcon
    case 1:
      return classes.redIcon
    case 2:
      return classes.greenIcon
    default:
      return classes.blueIcon
  }
}

export default MyDegree
