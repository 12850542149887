import * as React from 'react'
import VideocamIcon from '@material-ui/icons/Videocam'
import LaptopIcon from '@material-ui/icons/Laptop'
import PropTypes from 'prop-types'
import { Chip, Typography } from '@material-ui/core'

const OnlineEvent = (props) => {
  const { onlineURL, classes } = props

  if (onlineURL) {
    const isZoomLink = onlineURL.includes('zoom')
    return (
      <>
        <Chip
          onClick={() => window.open(onlineURL, '_blank')}
          size="medium"
          className={classes.eventOnlineButton}
          style={{ background: !isZoomLink && '#BDE1FF' }}
          label={isZoomLink ? 'Zoom' : 'Online'}
          icon={isZoomLink ? <VideocamIcon /> : <LaptopIcon />}
        />
        <Typography
          component="a"
          href={onlineURL}
          target="_blank"
          className={classes.eventOnlineLink}
        >
          {onlineURL}
        </Typography>
      </>
    )
  }
  return <Chip className={classes.eventOnlineButton} label="Online" />
}

OnlineEvent.propTypes = {
  classes: PropTypes.object,
  onlineURL: PropTypes.string,
}

export default OnlineEvent
