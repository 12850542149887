import React from 'react'
import { Card, Typography, CardContent, Grid } from '@material-ui/core'

const CRamenityCard = ({ data, classes }) => (
  <Card style={{ display: 'flex' }} variant="outlined">
    <CardContent className={classes.amenitiesItemContainer}>
      <Grid container>
        <Grid
          item
          xs={6}
          alignContent="center"
          direction="column"
          style={{ textAlign: 'center' }}
        >
          <Typography variant="h5">
            <b>{data?.title}</b>
          </Typography>
          <Typography variant="h6">
            <b>{data?.subtitle}</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.amenitiesImageDiv}>
            <img
              src={data.imageFinal}
              alt={
                data?.imageAltText
                  ? data?.imageAltText
                  : 'A lounge with several chairs and couches'
              }
              width="100%"
              aria-hidden="true"
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.amenitiesTextContainer}>
          <Typography variant="body1">{data?.listHeaderText}</Typography>
          {data?.amenitiesList.map((item) => (
            <Typography key={item} variant="body1">
              &bull;&nbsp;{item}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
)

export default CRamenityCard
