import * as React from 'react'
import {
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  CardActionArea,
} from '@material-ui/core'
import DateRangeIconAndDate from './DateRangeIconAndDate'
import {
  isoStringConverter,
  eventLength,
  formatOngoingEventDate,
} from './helpers/eventHelpers'
import OngoingeventBadge from './OngoingeventBadge'
import EventLocationAndVenueDisplay from './EventLocationAndVenueDisplay'

const CReventCard = ({ classes, handleClick, data }) => {
  const startDate = isoStringConverter(data.startsOn)
  const endDate = isoStringConverter(data.endsOn, false, true)
  const ongoingEvent = eventLength(data.startsOn, data.endsOn)
  const ongoingEventDate = formatOngoingEventDate(data.startsOn, data.endsOn)

  const engagePageURL = `${process.env.REACT_APP_ENGAGE_PAGE_LINK_BASE_URL}/${data.id}`
  return (
    <Card
      className={classes.eventCard}
      variant="outlined"
      aria-label={data.location_name}
      key={data.id}
    >
      <CardActionArea
        onClick={() => handleClick(engagePageURL, data.name)}
        className={classes.eventCardActionArea}
      >
        <CardMedia
          image={data.imageUrl}
          alt={`${data.name} event image`}
          className={classes.eventCardImage}
        />
        <CardContent className={classes.eventCardContent}>
          <Typography variant="h3" className={classes.eventCardTitle}>
            {data.name}
          </Typography>
          <DateRangeIconAndDate
            classes={classes}
            eventSatus={data.state_status}
            startDate={startDate}
            endDate={endDate}
            isOngoing={ongoingEvent}
            ongoingEventDate={ongoingEventDate}
            eventState={data.state_status}
          />
          <OngoingeventBadge isOngoing={ongoingEvent} />
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.eventCardActions}>
        <EventLocationAndVenueDisplay
          classes={classes}
          onlineURL={data.address_onlineLocation}
          venue={data.venue_venue}
          venueGeoLat={data.address_latitude}
          venueGeoLng={data.address_longitude}
          addressName={data.address_name}
          props={data}
        />
      </CardActions>
    </Card>
  )
}

export default CReventCard
