import * as React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography as Text,
  Grid,
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'

import { doc, collection, getDoc } from 'firebase/firestore'
import { firestore } from '../../../utils/firebase'

import { SideNavIcon } from '../sideNav/SideNav'
import { navigation } from '../sideNav/sidenav.constants'
import CommunityDialog from '../communityDialog/CommunityDialog'
import { addProfileLogEvent } from '../../features/dashboard/widgets/MyProfile/myProfileSlice'

import bottomTabBarStyles from './bottomTabBarStyles'
import {
  ROUTE,
  ROUTES,
  SEARCH,
  VEHICLE,
} from '../../features/maps/utils/constants'

/*
  Tab menu for mobile view.
*/

// TODO Update icon code to be able to handle custom pngs, like images/mapsIcon.png
function BottomTabBar() {
  const classes = bottomTabBarStyles()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [value, setValue] = React.useState(0)
  const [communityDialogOpen, setCommunityDialogOpen] = React.useState(false)
  const [currentTab, setCurrentTab] = useState({})
  const { bottomSheet } = useSelector((s) => s.maps)
  const [isBottomTabBarVisible, setIsBottomTabBarVisible] = useState(true)

  React.useEffect(() => {
    shouldDisplayBottomTabBar()
  }, [location, bottomSheet])

  React.useEffect(() => {
    shouldDisplayBottomTabBar()
  }, [location, bottomSheet])

  const bottomTabBarItems = navigation.routes.filter(
    (route) => route.Icon === null || route.title !== 'Settings',
  )

  const handleChange = (newValue) => {
    setValue(newValue)
  }

  const handleCommunityDialogClose = () => {
    setCommunityDialogOpen(false)
  }

  const handleCommunityRedirect = (tab) => {
    logNavClick(tab)
    window.location.replace(process.env.REACT_APP_MYCOMMUNITY_URL)
  }

  const logNavClick = (tab) => {
    if (process.env.REACT_APP_FIREBASE_ON === 'true') {
      dispatch(
        addProfileLogEvent({
          module: 'SideNav',
          object: 'SideNavButton',
          objectId: 'SideNavItem',
          action: 'Click',
          actionContext: 'SideNavClick',
          actionContextValue: tab.title,
          rcpid: localStorage.getItem('rutgersEduRCPID'),
          url: tab.path,
          timestamp: Date.now(),
        }),
      )
    }
  }

  function shouldDisplayBottomTabBar() {
    let shouldShow = true
    const dontShowList = [
      '/login',
      '/SeeAllAppsPageMobile',
      '/NearestStopsMobile',
      '/ManageNewsMobile',
      '/ManageWidgetsMobile',
    ]

    const bottomSheets = [SEARCH, ROUTES, ROUTE, VEHICLE]
    // Current rule: don't show if the path is in the dontShowList
    if (dontShowList.includes(location.pathname)) {
      shouldShow = false
    }

    if (
      bottomSheets.includes(bottomSheet.component) &&
      location.pathname === '/maps'
    ) {
      shouldShow = false
    }

    setIsBottomTabBarVisible(shouldShow)
  }

  const fetchUserState = async () => {
    const docRef = doc(
      collection(firestore, 'users'),
      localStorage.getItem('user') ?? 'visitor',
      'settings',
      'userState',
    )

    try {
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        const userStateData = docSnap
        return userStateData
      }
      console.log('User state document does not exist')
      return null
    } catch (error) {
      console.error('Error fetching user state:', error)
      throw error
    }
  }

  const handleButtonNavClick = (tab) => {
    setCurrentTab(tab)
    if (tab.title === 'Community') {
      fetchUserState().then((snapshot) => {
        const communityAcknowledgement = snapshot.data().acknowledgedCommunity
        console.log(
          `Community Acknowledgement returned: ${communityAcknowledgement}`,
        )
        if (!communityAcknowledgement) setCommunityDialogOpen(true)
        else handleCommunityRedirect(tab)
      })
    } else {
      logNavClick(tab)
      history.push(tab.path)
    }
  }

  return (
    isBottomTabBarVisible && (
      <>
        <BottomNavigation
          value={value}
          onChange={handleChange}
          className={classes.container}
          showLabels
        >
          {bottomTabBarItems.map((tab) => (
            <BottomNavigationAction
              key={tab.path}
              value={tab.title}
              className={classes.root}
              onClick={() => handleButtonNavClick(tab)}
              icon={
                <Grid container direction="column">
                  <Grid item>
                    {tab.title === 'Community' ? (
                      <SideNavIcon svgPathIcon={tab.SvgPath} />
                    ) : (
                      <tab.Icon
                        className={
                          location.pathname === tab.path
                            ? classes.iconSelected
                            : classes.icon
                        }
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <Text
                      className={
                        location.pathname === tab.path
                          ? classes.textSelected
                          : classes.text
                      }
                    >
                      {tab.title}
                    </Text>
                  </Grid>
                </Grid>
              }
            />
          ))}
        </BottomNavigation>
        <CommunityDialog
          classes={classes}
          open={communityDialogOpen}
          handleClose={handleCommunityDialogClose}
          handleRedirect={() => handleCommunityRedirect(currentTab)}
        />
      </>
    )
  )
}

export default BottomTabBar
