import { createTheme } from '@material-ui/core'

export const customColor = {
  black: '#181818',
  lighterBlack: '#303030',
  blue: '#2F6FF3',
  bluePart2: '#558BF7',
  blueGray: '#5d6799',
  darkGray: '#444444',
  darkSlateGray: '#313131',
  darkerSlateGray: '#232322',
  libraryQuickSearchCard: '#1e70bf',
  gray: '#737373',
  gainsBoro: '#DCDCDC',
  green: '#00b339',
  yellow: '#f9c642',
  lightGray: '#cbcbcb',
  lighterGray: '#f3f3f3',
  linearGradientWhite: 'linear-gradient(#FDFDFD, #EEEEEE)',
  rutgersRed: '#cc0033',
  rutgersRed2: '#FF4242',
  lighterRed: '#DD3333',
  darkerRed: '#9a0126',
  white: '#ffffff',
  whitesmoke: '#eeeeee',
  lighterWhitesmoke: '#fcfcfc',
  primaryHeaderText: '#2d2d2e',
  primaryHeaderTextDark: '#ffffff',
  secondaryHeaderText: '#737373',
  secondaryHeaderTextDark: '#c0c0c0',
  sideNavColor: '#E79494',
  sideNavBackgroundColor: '#DD3333',
  rcsaBlue: '#006FA8',
  linkText: '#4183C4',
  linkTextDark: '#91DAFF',
}

export function theme(dark) {
  const palleteType = dark ? 'dark' : 'light'
  const mainPrimaryColor = dark ? customColor.white : customColor.rutgersRed
  const mainPrimaryContast = dark ? customColor.rutgersRed : customColor.white

  const headerTextPrimaryColor = dark
    ? customColor.primaryHeaderTextDark
    : customColor.primaryHeaderText

  const mainSecondaryColor = dark ? customColor.white : customColor.rutgersRed

  const headerTextSecondaryColor = dark
    ? customColor.secondaryHeaderTextDark
    : customColor.secondaryHeaderText

  const mainBackground = dark ? customColor.black : customColor.whitesmoke
  const secondaryBackgroundColor = dark
    ? customColor.darkSlateGray
    : customColor.white
  const ternaryBackground = dark
    ? customColor.darkGray
    : customColor.lighterGray

  const palette = {
    color: customColor,
    palette: {
      type: palleteType,
      primary: {
        main: mainPrimaryColor,
        contrastText: mainPrimaryContast,
        headerText: headerTextPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
        headerText: headerTextSecondaryColor,
      },
      paper: {
        primary: dark ? customColor.darkerSlateGray : customColor.white,
        secondary: dark ? customColor.darkGray : customColor.lighterGray,
        dragAndDrop: dark ? customColor.darkGray : customColor.white,
      },
      base: {
        blue: dark ? customColor.bluePart2 : customColor.blue,
        blueGray: customColor.blueGray,
        black: customColor.black,
        darkGray: customColor.darkGray,
        gray: customColor.gray,
        lightGray: customColor.lightGray,
        green: customColor.green,
        red: dark ? customColor.rutgersRed2 : customColor.rutgersRed,
        rutgersRed: customColor.rutgersRed,
        white: customColor.white,
        yellow: customColor.yellow,
      },
      backgroundColor: {
        default: customColor.gainsBoro,
        main: mainBackground,
        secondary: secondaryBackgroundColor,
        ternary: ternaryBackground,
      },
      components: {
        appBar: dark
          ? customColor.darkerSlateGray
          : customColor.primaryHeaderTextDark,
        sideNav: dark ? customColor.black : customColor.rutgersRed,
        icon: dark ? customColor.white : customColor.gray,
        sideNavIcon: dark ? customColor.white : customColor.white,
        topBarIcon: dark ? customColor.gray : customColor.rutgersRed,
        sideNavHover: dark ? customColor.darkSlateGray : customColor.lighterRed,
        tabBackground: dark
          ? customColor.darkerSlateGray
          : customColor.whitesmoke,
        newsBackground: dark
          ? customColor.darkerSlateGray
          : customColor.whitesmoke,
        newsSectionBackground: dark
          ? customColor.darkerSlateGray
          : customColor.linearGradientWhite,
        bottomSheetOverlay: dark
          ? customColor.lighterWhitesmoke
          : customColor.white,
        rcsaBlue: dark ? customColor.white : customColor.rcsaBlue,
        linkText: dark ? customColor.linkTextDark : customColor.linkText,
      },
      button: {
        hover: dark ? customColor.darkGray : customColor.lightGray,
        hoverLight: dark ? customColor.darkGray : customColor.lighterGray,
        hoverDark: dark
          ? customColor.secondaryHeaderText
          : customColor.lighterGray,
        focusOutline: customColor.blue,
        focusBorder: dark ? customColor.darkSlateGray : customColor.white,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 769,
        lg: 1024,
        xl: 1280,
        xxl: 1536,
      },
    },
  }

  const breakpoints = createTheme(palette)

  palette.overrides = {
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: null,
          filter: 'brightness(75%)',
        },
      },
    },
    MuiChip: {
      root: {
        '&:hover': {
          filter: 'brightness(100%)',
        },
      },
    },
    MuiAlert: {
      filledWarning: {
        backgroundColor: '#a86500',
      },
    },
  }

  palette.typography = {
    fontFamily: ['Inter', 'system-ui', 'sans-serif'].join(','),
    h1: {
      fontWeight: 400,
      fontSize: '2.5rem',
      letterSpacing: '-0.025em',
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: '1.75rem',
      },
    },
    h2: {
      fontWeight: 400,
      fontSize: '2.25rem',
      letterSpacing: '-0.025em',
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: '1.75rem',
      },
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.75rem',
      letterSpacing: '-0.025em',
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: '1.75rem',
      },
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.5rem',
      letterSpacing: '-0.025em',
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: '1.75rem',
      },
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.25rem',
      letterSpacing: '-0.025em',
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    h6: {
      fontWeight: 400,
      fontSize: '1.125rem',
      letterSpacing: '-0.025em',
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 600,
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 600,
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    button: {
      textTransform: 'none',
      fontSize: '0.875rem',
      fontWeight: 500,
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.875rem',
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    overline: {},
  }

  return createTheme(palette)
}

export default theme
