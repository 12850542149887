import { makeStyles } from '@material-ui/core/styles'

export const announcementsStyles = makeStyles((theme) => ({
  announcementsContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    background: theme.palette.primary.paper,
    width: '100%',
    maxHeight: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  announcementContainer: {
    borderRadius: '1rem',
    width: '32%',
    margin: '10px 7px',
    [theme.breakpoints.down(1589)]: {
      width: '45%',
    },
    [theme.breakpoints.down(970)]: {
      width: '100%',
    },
  },
  announcementTitle: {
    paddingBottom: '20px',
  },
}))

export default announcementsStyles
