import { makeStyles } from '@material-ui/core/styles'

export const sortableItemStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    border: '1px solid black',
    margin: '10px 0',
    padding: '0px 5px',
    background: theme.palette.paper.dragAndDrop,
    touchAction: 'none',
    boxShadow: '0 0 5px rgba(0,0,0,0.3)',
    borderColor: theme.palette.base.lightGray,
    '-webkit-user-select': 'none' /* Safari */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* IE10+/Edge */,
    'user-select': 'none' /* Standard */,
    borderRadius: '.5em',
  },
  rowContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-5px',
  },
  divider: {
    width: '100%',
    backgroundColor: theme.palette.base.red,
  },
  trashCanIcon: {
    opacity: '0.3',
    color: theme.palette.base.red,
    '&:hover': {
      opacity: '1',
    },
  },
}))

export default sortableItemStyles
