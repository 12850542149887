import * as React from 'react'
import { Typography, Box } from '@material-ui/core'
import DateRangeIcon from '@material-ui/icons/DateRange'
import PropTypes from 'prop-types'
import { eventSatus } from './eventCardParams'
const DateRangeIconAndDate = ({
  isOngoing,
  startDate,
  endDate,
  ongoingEventDate,
  eventState,
  classes,
}) => (
  <Box className={classes.eventDateContainer}>
    <DateRangeIcon className={classes.eventCardIcon} />
    <Typography
      variant="body1"
      component="div"
      style={{
        color: eventState === eventSatus.CANCELED_EVENT && '#CC0033',
        textDecoration:
          eventState === eventSatus.CANCELED_EVENT && 'line-through',
      }}
    >
      {isOngoing ? ongoingEventDate : `${startDate} ${endDate}`}
    </Typography>
  </Box>
)

DateRangeIconAndDate.propTypes = {
  eventState: PropTypes.string.isRequired,
  isOngoing: PropTypes.bool.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  ongoingEventDate: PropTypes.string,
}

export default DateRangeIconAndDate
