/* eslint-disable */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UAParser } from 'ua-parser-js'
import { Link, useLocation, useHistory } from 'react-router-dom'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

// import Carousel from 'react-material-ui-carousel'

import QueryString from 'qs'
import Cookies from 'js-cookie'
import {
  //   fetchFirebaseImage,
  //   fetchLoginHints,
  fetchNGUserInfo,
  loginVisitor,
  selectFetchNGUserInfoStatus,
  //   selectLoginHints,
  selectUserLoginStatusMessage,
} from './loginSlice'
import styles from './Login.module.css'
import loginClasses from './LoginStyles'
import rutgersLogo from './images/rutgers-shield-logo-new.svg'
import { fetchProfileLogEvent } from '../dashboard/widgets/MyProfile/myProfileSlice'
import Announcements from './Announcements/Announcements'
// import { fetchMyApps } from '../apps/appSlice'

const parser = new UAParser()

export function Login() {
  const classes = loginClasses()
  const history = useHistory()
  const dispatch = useDispatch()
  //   const hints = useSelector(selectLoginHints)
  const fetchUserStatus = useSelector(selectFetchNGUserInfoStatus)
  const statusMessage = useSelector(selectUserLoginStatusMessage)

  const { code } = QueryString.parse(useLocation().search, {
    ignoreQueryPrefix: true,
  })

  const handleDebugLoginButtonClicked = () => {
    dispatch(fetchNGUserInfo(code))
    history.push('/')
  }

  const handleVisitorLogin = () => {
    dispatch(loginVisitor())
    // user logged in successfully
    dispatch(
      fetchProfileLogEvent({
        module: 'Core',
        object: 'Auth',
        objectId: 'Login',
        action: 'Click',
        actionContext:
          localStorage.getItem('user') === '"visitor"' ? 'Guest' : 'NetID',
        actionContextValue: '',
        rcpid:
          localStorage.getItem('rutgersEduRCPID') === '"visitor"'
            ? 'Guest'
            : localStorage.getItem('rutgersEduRCPID'),
        info: JSON.stringify(parser.getResult()),
        timestamp: Date.now(),
      }),
    )
  }

  React.useEffect(() => {
    if (fetchUserStatus === 'fulfilled') {
      history.push('/')
    }
  }, [fetchUserStatus])

  // update this to check for token instead of a code
  // myrJwtToken
  // proceed to fetch the user info
  React.useEffect(() => {
    // dispatch(fetchLoginHints())
    const jwtToken = Cookies.get('myrJwtToken')
    if (jwtToken && fetchUserStatus !== 'fulfilled') {
      dispatch(fetchNGUserInfo(jwtToken)).then(() => {
        // user logged in successfully
        dispatch(
          fetchProfileLogEvent({
            module: 'Core',
            object: 'Auth',
            objectId: 'Login',
            action: 'Click',
            actionContext:
              localStorage.getItem('user') === '"visitor"' ? 'Guest' : 'NetID',
            actionContextValue: '',
            rcpid:
              localStorage.getItem('rutgersEduRCPID') === '"visitor"'
                ? 'Guest'
                : localStorage.getItem('rutgersEduRCPID'),
            info: JSON.stringify(parser.getResult()),
            timestamp: Date.now(),
          }),
        )
      })
    }
  }, [])
  return (
    <div className={styles.fullHeight}>
      {fetchUserStatus === 'error' && (
        <Alert severity="error" style={{ width: '100%', position: 'fixed' }}>
          {statusMessage}
        </Alert>
      )}
      {fetchUserStatus === 'fulfilled' && (
        <Alert severity="success" style={{ width: '100%', position: 'fixed' }}>
          {statusMessage}
        </Alert>
      )}
      <Grid container className={styles.fullHeight}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={styles.fullHeight}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
              className={styles.fullHeight}
            >
              <img
                src={`${rutgersLogo}`}
                alt="rutgers emblem"
                className={`${styles.rutgersLogo}`}
              />
              {fetchUserStatus === 'pending' && (
                <Box
                  my={4}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="75%"
                >
                  <CircularProgress />
                </Box>
              )}
              {fetchUserStatus !== 'pending' && (
                <Box
                  my={4}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="75%"
                >
                  <a
                    className={styles.loginButton}
                    href={process.env.REACT_APP_LOGIN_LINK}
                  >
                    NetID Login
                  </a>
                  {window.location.hostname === 'localhost' && (
                    <Button
                      onClick={(e) => {
                        e.preventDefault()
                        handleDebugLoginButtonClicked(code)
                      }}
                      style={{ color: 'blue' }}
                    >
                      Sign me in! (Click Test Login button, sign in, copy code
                      in url from beta-my, paste into localhost:3000 and hit
                      enter in this url, then click me)
                    </Button>
                  )}
                  <a
                    className={styles.loginButton}
                    href={process.env.REACT_APP_LOGIN_COMMUNITY_LINK}
                  >
                    CommunityID Login
                  </a>
                  <Button
                    color="secondary"
                    variant="outlined"
                    to="/"
                    component={Link}
                    onClick={handleVisitorLogin}
                    className={styles.loginButton}
                    style={{ marginTop: 'auto' }}
                  >
                    Continue as a Visitor
                  </Button>
                  <Box mt={1}>
                    <a
                      className={styles.simpleLink}
                      href="https://it.rutgers.edu/help-support/"
                    >
                      Need help logging in?
                    </a>
                  </Box>
                </Box>
              )}
            </Box>
            <Box style={{ marginBottom: 10 }}>
              <a
                href="https://www.rutgers.edu/copyright-information"
                target="_blank"
                rel="noopener noreferer"
                className={classes.copyRightText}
              >
                Copyright ©
              </a>{' '}
              <a
                href="https://www.rutgers.edu"
                target="_blank"
                rel="noopener noreferer"
                className={classes.copyRightText}
              >
                Rutgers, The State University of New Jersey. All rights
                reserved.
              </a>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          lg={8}
          className={classes.heroImage}
        >
          <Box>
            <Announcements />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
