import * as React from 'react'
import { Box, Typography, Grid, Divider } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBack'
import crStyles from '../crStyles'

const CRaboutUs = ({ handleClick, data }) => {
  const classes = crStyles()
  const handlePress = (e) => {
    console.log(1234, 'aboutPress')
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      handleClick(true)
    }
  }
  return (
    <Box>
      <Box
        className={classes.aboutUsHeaderContainer}
        tabIndex={0}
        onClick={handleClick}
        onKeyPress={handlePress}
      >
        <ArrowBackIosIcon className={classes.aboutUsHeaderBackArrow} />
        <Typography variant="h4" className={classes.aboutUsNavText}>
          {data.navText}
        </Typography>
      </Box>
      <Divider />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.aboutUsTextContainer}
      >
        <Grid item>
          <div className={classes.aboutUsImageDiv}>
            <img src={data.imageFinal} alt="RCSA Logo" width="100%" />
          </div>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            style={{ lineHeight: '18px' }}
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
export default CRaboutUs
