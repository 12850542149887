import { makeStyles } from '@material-ui/core/styles'

export const NewsSourceListStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0px',
    },
  },
  listItemBody: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1, 0),
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
    },
  },
  listItemText: {
    color: theme.palette.primary.headerText,
  },
}))
