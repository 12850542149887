import { makeStyles } from '@material-ui/core/styles'

export const eventCardStyles = makeStyles((theme) => ({
  eventCard: {
    width: '95%',
    borderRadius: '1rem',
    margin: theme.spacing(2, 1),
    border: `1px solid ${theme.color.lightGray}`,
    backgroundColor: theme.color.linearGradientWhite,
  },
  eventCardActionArea: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  eventCardActions: {
    padding: theme.spacing(0, 1, 1, 1),
  },
  eventsTabContent: {
    height: '358px',
    width: '335px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  eventCardImage: {
    height: '120px',
    backgroundSize: 'contain',
    backgroundColor: theme.palette.components.newsBackground,
  },
  eventCardContent: {
    padding: theme.spacing(1, 1, 0, 1),
  },
  eventDateContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
  },
  eventCardIconAndDescription: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: theme.palette.button.hoverDark,
      cursor: 'pointer',
    },
    borderRadius: theme.spacing(1),
    paddingRight: '10px',
  },
  eventCardIcon: {
    marginRight: '10px',
  },
  eventCardLocationIcon: {
    marginRight: '9px',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    borderRadius: '50%',
    border: `2px solid transparent`,
    marginLeft: theme.spacing(-0.25),
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    fontSize: 24,
  },
  eventCardTitle: {
    margin: theme.spacing(1, 0),
    fontWeight: 'bold',
    textDecoration: 'none',
    color: theme.palette.primary.headerText,
  },
  eventCanceledText: {
    color: theme.palette.base.rutgersRed,
    fontWeight: 'bold',
  },
  eventOnlineButton: {
    background: '#CCCCFF',
    border: `1px solid ${theme.color.black}`,
    fontWeight: 700,
    marginRight: theme.spacing(2),
  },
  eventOnlineLink: {
    width: '70%',
    whiteSpace: 'nowrap',
    overflow: ' hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
    color: theme.color.black,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  eventCardOngoingBadge: {
    background: '#42eff5',
    color: theme.color.black,
    borderRadius: theme.spacing(1),
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.25, 0.5),
    textAlign: 'center',
  },
}))
