/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'

import { doc, collection, getDoc } from 'firebase/firestore'
import { Alert } from '@material-ui/lab'
import {
  Grid,
  Typography as Text,
  IconButton,
  Button,
  Box,
  MenuItem,
  Select,
  InputLabel,
  Tooltip,
  Card,
  Snackbar,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import DateRangeIcon from '@material-ui/icons/DateRange'
import WorkOutlineOutlined from '@material-ui/icons/WorkOutlineOutlined'
import BookOutlinedIcon from '@material-ui/icons/BookOutlined'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import DirectionsBusOutlinedIcon from '@material-ui/icons/DirectionsBusOutlined'
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined'
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

import Widget from '../Widget'
import courseScheduleStyles from './courseScheduleStyles'
import {
  fetchCourseSchedule,
  selectCourseScheduleData,
  selectCourseScheduleStatus,
  selectShowCourseScheduleTutorial,
  endTutorial,
  selectCourseScheduleModalOpen,
  toggleCourseScheduleModal,
  selectTab,
  selectCurrentTerm,
  updateCurrentTerm,
  updateTab,
  fetchCourseDefaultTutorialData,
  selectDefaultTutorialData,
  selectDefaultTutorialStatus,
} from './courseScheduleSlice'
import CourseScheduleModal from './courseScheduleModal'
import {
  courseScheduleTutorial,
  courseScheduleTutorialTwoUserRoles,
  courseScheduleTutorialForRU,
} from './courseScheduleTutorial'
import Loading from '../helpers/Loading'
import {
  RutgersTabs,
  RutgersTab,
} from '../../DashboardComponents/Tabs/RutgersTabs'
import { a11yProps } from '../../DashboardComponents/TabPanel/TabPanel'

import tutorialStyles from '../../DashboardComponents/Tutorial/TutorialStyles'
import tutorialStylesForBothUserRoles from '../../DashboardComponents/Tutorial/TutorialStylesForDualRole'
import TutorialMode from '../../DashboardComponents/Tutorial/TutorialMode'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'

import {
  fetchRoutes,
  fetchStops,
} from '../../../maps/sections/Map/redux/myMapsSlice'
import { logDashboardEvent } from '../../events'
import CheckIsMobile from '../../../../../utils/isMobileHook'
import { fetchBuildings } from '../../../maps/sections/Buildings/redux/myBuildingsSlice'
import { firestore } from '../../../../../utils/firebase'
import {
  handleShowBottomSheetBuilding,
  // handleShowBottomSheetNearestStop,
} from './helper'

// eslint-disable-next-line no-unused-vars
function CourseSchedule({ className, title, widgetId }) {
  const classes = courseScheduleStyles()
  const dispatch = useDispatch()
  const courseScheduleData = useSelector(selectCourseScheduleData)
  const widgetCallState = useSelector(selectCourseScheduleStatus)
  const showTutorial = useSelector(selectShowCourseScheduleTutorial)
  const stateEndTutorial = useSelector(endTutorial)
  const courseModalOpen = useSelector(selectCourseScheduleModalOpen)
  const tab = useSelector(selectTab)
  const currentTerm = useSelector(selectCurrentTerm)

  const widgetTutorialData = useSelector(selectDefaultTutorialData)
  const widgetTutorialDataCallState = useSelector(selectDefaultTutorialStatus)

  const tutorialStylesClasses = tutorialStyles()
  const tutorialStylesForBothUserRolesClasses = tutorialStylesForBothUserRoles()

  const [isBoth, setIsBoth] = useState(false)
  const [tutorial, setTutorial] = useState(courseScheduleTutorial)
  const [tutorialClasses, setTutorialClasses] = useState(tutorialStylesClasses)

  useEffect(() => {
    dispatch(fetchCourseSchedule()).then((responseData) => {
      if (
        responseData?.payload[0]?.data?.rbhs &&
        responseData?.payload[0]?.data?.rbhs.length > 0
      ) {
        dispatch(updateTab('rbhs'))
      }
      if (
        responseData?.payload[0]?.data?.rbhs &&
        responseData?.payload[0]?.data?.rbhs?.length > 0 &&
        responseData?.payload[0]?.data?.ru &&
        responseData?.payload[0]?.data?.ru.length > 0
      ) {
        setIsBoth(true)
        setTutorial(courseScheduleTutorialTwoUserRoles)
        setTutorialClasses(tutorialStylesForBothUserRolesClasses)
      } else {
        setIsBoth(false)
        setTutorial(
          responseData?.payload[0]?.data?.rbhs?.length > 0
            ? courseScheduleTutorial
            : courseScheduleTutorialForRU,
        )
        setTutorialClasses(tutorialStylesClasses)
      }
    })
    dispatch(fetchBuildings())
    dispatch(fetchRoutes())
    dispatch(fetchStops())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content className={classes.content}>
        {renderCourseScheduleState(
          widgetCallState,
          tab,
          currentTerm,
          courseModalOpen,
          courseScheduleData,
          showTutorial,
          stateEndTutorial,
          dispatch,
          widgetId,
          widgetTutorialData,
          widgetTutorialDataCallState,
          isBoth,
          tutorial,
          tutorialClasses,
        )}
      </Widget.Content>
    </Widget>
  )
}

function renderCourseScheduleState(
  widgetCallState,
  tab,
  currentTerm,
  courseModalOpen,
  courseScheduleData,
  showTutorial,
  stateEndTutorial,
  dispatch,
  widgetId,
  widgetTutorialData,
  widgetTutorialDataCallState,
  isBoth,
  tutorial,
  tutorialClasses,
) {
  const classes = courseScheduleStyles()
  const showDummyData =
    (courseScheduleData.ru === null || courseScheduleData.rbhs === null) &&
    !widgetTutorialData &&
    showTutorial
  if (showDummyData) {
    dispatch(fetchCourseDefaultTutorialData())
  }
  if (
    (courseScheduleData.ru === null || courseScheduleData.rbhs === null) &&
    showTutorial
  ) {
    switch (widgetTutorialDataCallState) {
      case 'fulfilled':
        return (
          <Loaded
            scheduleData={widgetTutorialData}
            tab={tab}
            currentTerm={currentTerm}
            courseModalOpen={courseModalOpen}
            showTutorial={showTutorial}
            stateEndTutorial={stateEndTutorial}
            dispatch={dispatch}
            widgetId={widgetId}
            isBoth={isBoth}
            tutorial={tutorial}
            tutorialClasses={tutorialClasses}
          />
        )
      case 'pending':
        return <Loading />
      case 'error':
        return (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <ErrorMessage />
          </Grid>
        )
      default:
        return (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className={classes.text}></Grid>
          </Grid>
        )
    }
  } else {
    switch (widgetCallState) {
      case 'fulfilled':
        return (
          <Loaded
            scheduleData={courseScheduleData}
            tab={tab}
            currentTerm={currentTerm}
            courseModalOpen={courseModalOpen}
            showTutorial={showTutorial}
            stateEndTutorial={stateEndTutorial}
            dispatch={dispatch}
            widgetId={widgetId}
            isBoth={isBoth}
            tutorial={tutorial}
            tutorialClasses={tutorialClasses}
          />
        )
      case 'pending':
        return <Loading />
      case 'error':
        return (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <ErrorMessage />
          </Grid>
        )
      default:
        return (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className={classes.text}></Grid>
          </Grid>
        )
    }
  }
}

function CourseObject({
  course,
  greyBackground,
  dispatch,
  widgetId,
  isMobile,
}) {
  const classes = courseScheduleStyles()
  const history = useHistory()
  const [alert, showAlert] = React.useState(false)
  const { buildings } = useSelector((s) => s.buildings)

  const handleBookstoreInfoButton = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        `${course.courseTitle} Bookstore Info`,
        course.bookStoreUrl,
      )
    }
    window.open(course.bookStoreUrl, '_blank')
  }

  const handleBusButton = () => {
    const success = handleShowBottomSheetBuilding(
      dispatch,
      course.bldgInfo.bldgNo,
      buildings,
    )
    if (success) {
      if (widgetId && dispatch) {
        logDashboardEvent(
          dispatch,
          'dashboardEvent',
          widgetId,
          `${course.courseTitle} Bus`,
          '/maps',
        )
      }
      if (isMobile) {
        history.push('/NearestStopsMobile')
      } else {
        history.push('/maps')
      }
    } else {
      showAlert(true)
    }
  }

  const handleDirectionButton = () => {
    const directionsUrl = `https://maps.rutgers.edu/#/?click=true&parking=true&selected=${course.bldgInfo.bldgNo}&sidebar=true&zoom=16`
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        `${course.courseTitle} Direction`,
        directionsUrl,
      )
    }
    // Will come back later 8/24/23
    // const buildingID = course.bldgInfo.bldgNo // Pass this into bellow function
    // const buildingObj = buildings.find(
    //   (building) => building.bid === buildingID,
    // )
    // if (!isMobile) {
    //   dispatch(handleViewBuildingInfoSheet(buildingID))
    // }
    // dispatch(setSelectedBuilding(buildingObj))
    // dispatch(fetchParkingNearBuilding())
    // history.push('/maps')
    // Open Maps and show nearest parking
    window.open(directionsUrl, '_blank')
  }

  const handleBuildingButton = () => {
    const gpsCoords = course.bldgInfo.bldgVM?.gpsCor?.split(',')
    const buildingUrl = `https://maps.rutgers.edu/#/?click=true&lat=${gpsCoords[0]}&lng=${gpsCoords[1]}&selected=${course.bldgInfo.bldgNo}&sidebar=true&zoom=16`
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        `${course.courseTitle} Direction`,
        buildingUrl,
      )
    }
    // Will come back later 8/24/23
    // const buildingID = course.bldgInfo.bldgNo // Pass this into bellow function
    // const buildingObj = buildings.find(
    //   (building) => building.bid === buildingID,
    // )
    // if (!isMobile) {
    //   dispatch(handleViewBuildingInfoSheet(buildingID))
    // }
    // dispatch(setSelectedBuilding(buildingObj))
    // dispatch(fetchParkingNearBuilding())
    // history.push('/maps')
    // Open Maps and show nearest parking
    window.open(buildingUrl, '_blank')
  }

  const handleClassRoomButton = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        `${course.courseTitle} Classroom`,
        course.dcsUrl,
      )
    }
    window.open(course.dcsUrl, '_blank')
  }
  const examDay = new Date(course.examDay)
  const daysOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
  const dayOfWeek = daysOfWeek[examDay.getDay()]

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      className={greyBackground ? classes.greyBackground : null}
      style={{ paddingLeft: 8, paddingTop: 8, paddingBottom: 4 }} // This needs to be here because of conditional styling
    >
      <Grid
        container
        className={
          greyBackground
            ? classes.greyBackgroundText
            : classes.regularBackgroundText
        }
      >
        <Text variant="subtitle1">{course.courseTitle}</Text>
      </Grid>

      <Grid container spacing={1}>
        <Grid item>
          <Text variant="subtitle2">
            Course #:{' '}
            <span className={classes.subtitleSpan}>{course.courseNo}</span>
          </Text>
        </Grid>

        <Grid item>
          <Text variant="subtitle2">
            Section:{' '}
            <span className={classes.subtitleSpan}>{course.sectionNo}</span>
          </Text>
        </Grid>

        <Grid item>
          <Text variant="subtitle2">
            Credits:{' '}
            <span className={classes.subtitleSpan}>
              {course.creditsAttempted}
            </span>
          </Text>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              {course.instructorOne && (
                <Text variant="subtitle2">
                  Instructor:{' '}
                  <span className={classes.subtitleSpan}>
                    {course.instructorOne}
                  </span>
                </Text>
              )}
            </Grid>
            <Grid item>
              {course.classTime && (
                <Text variant="subtitle2">
                  Time:{' '}
                  <span className={classes.subtitleSpan}>
                    {course.classTime
                      ? `${course.meetingDay} ${course.classTime}`
                      : 'N/A'}
                  </span>
                </Text>
              )}
            </Grid>
            <Grid item>
              <Text variant="subtitle2">
                Course Type:{' '}
                <span className={classes.subtitleSpan}>
                  {course.courseType ? course.courseType : 'N/A'}
                </span>
              </Text>
            </Grid>
            <Grid item>
              {course.bldgInfo?.bldgVM?.bldgDescr === null ? null : (
                <Text variant="subtitle2">
                  Location:{' '}
                  {course.dcsUrl ? (
                    <a
                      href={course.dcsUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className={classes.subtitleSpan}>
                        {course.bldgInfo?.bldgVM?.bldgDescr
                          ? `${course.bldgInfo?.bldgVM?.bldgDescr} (Room: ${course.roomNo})`
                          : 'N/A'}
                      </span>
                    </a>
                  ) : (
                    <span className={classes.subtitleSpan}>
                      {course.bldgInfo?.bldgVM?.bldgDescr
                        ? `${course.bldgInfo?.bldgVM?.bldgDescr} (Room: ${course.roomNo})`
                        : 'N/A'}
                    </span>
                  )}
                </Text>
              )}
            </Grid>
            <Grid item>
              <Text variant="subtitle2">
                Campus:{' '}
                <span className={classes.subtitleSpan}>
                  {course.campusName ? course.campusName : 'N/A'}
                </span>
              </Text>
            </Grid>
            <Grid item>
              <Text variant="subtitle2">
                Final Exam:{' '}
                <span className={classes.subtitleSpan}>
                  {course.examDay ? (
                    <>{`${dayOfWeek !== undefined ? dayOfWeek : ''} ${
                      course.examDay
                    } ${course.examTime ? course.examTime : ''}`}</>
                  ) : (
                    'N/A'
                  )}
                </span>
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.rightInfoContainer}>
          <Grid container direction="column">
            <Grid item>
              {course.campus === null ? null : (
                <Text
                  className={
                    greyBackground
                      ? classes.greyBackgroundText
                      : classes.regularBackgroundText
                  }
                >
                  {course.campus}
                </Text>
              )}
            </Grid>
            <Grid item>
              {course.className && (
                <Text variant="body2">{course.className}</Text>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center">
        {course.bookStoreUrl ? (
          <Grid item>
            <Tooltip title="Bookstore">
              <IconButton
                aria-label="book store url"
                onClick={() => handleBookstoreInfoButton()}
              >
                <BookOutlinedIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : null}
        {course.bldgInfo?.bldgURL ? (
          <Grid item>
            <Tooltip title="Show Building">
              <IconButton
                aria-label="show building"
                onClick={() => handleBuildingButton()}
              >
                <LocationOnOutlinedIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : null}
        {course.bldgInfo?.bldgURL ? (
          <Grid item>
            <Tooltip title="Parking Directions">
              <IconButton
                aria-label="parking lot directions"
                onClick={() => handleDirectionButton()}
              >
                <DirectionsCarOutlinedIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : null}
        {course.bldgInfo?.bldgURL ? (
          <Grid item>
            <Tooltip title="Nearest Stops">
              <IconButton
                aria-label="nearest stops"
                onClick={() => handleBusButton()}
              >
                <DirectionsBusOutlinedIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : null}
        {course.dcsUrl ? (
          <Grid item>
            <Tooltip title="Classroom">
              <IconButton
                aria-label="classroom link"
                onClick={() => handleClassRoomButton()}
              >
                <AccountBalanceIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : null}
      </Grid>
      <Snackbar
        autoHideDuration={3000}
        open={alert}
        onClose={() => showAlert(false)}
      >
        <Alert severity="error">Error! Cannot navigate to maps page</Alert>
      </Snackbar>
    </Grid>
  )
}

function Loaded({
  scheduleData,
  tab,
  currentTerm,
  courseModalOpen,
  stateEndTutorial,
  dispatch,
  widgetId,
  isBoth,
  // tutorial,
  // tutorialClasses,
}) {
  const isMobile = CheckIsMobile()
  const classes = courseScheduleStyles()

  const [courseList, setCourseList] = useState([])
  const tutorialClasses = tutorialStyles()
  const tutorialModeSteps = courseScheduleTutorial
  const [showTutorialBool, setShowTutorial] = React.useState(false)

  useEffect(() => {
    setCourseList(scheduleData)
  }, [scheduleData])

  const handleExportSchedule = () => {
    const newUrl = scheduleData[`${tab}CourseCalendar`]
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardLinkEvent',
        widgetId,
        'Export Schedule',
        newUrl,
      )
    }
    window.open(newUrl, '_blank')
  }

  const handleSelfReport = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardLinkEvent',
        widgetId,
        'Self Report',
        process.env.REACT_APP_SELF_REPORT_LINK,
      )
    }
    window.open(process.env.REACT_APP_SELF_REPORT_LINK, '_blank')
  }

  const handleExpandedCourseView = () => {
    // open modal then make call to terms, then the call to course schedule for initial term based on termName and year turned
    if (tab === 'rbhs') {
      handleExportSchedule()
    } else {
      dispatch(toggleCourseScheduleModal(true))
    }
  }
  const handleCourseTermChange = (e) => {
    // console.log('set term value ', e.target.value)
    dispatch(updateCurrentTerm(e.target.value))
    const currentTermParam = scheduleData.ruTerms[e.target.value]
      ? `?term=${scheduleData.ruTerms[e.target.value]}`
      : ''
    dispatch(fetchCourseSchedule(currentTermParam))
  }
  const handleChange = (e, newValue) => {
    dispatch(updateTab(newValue))
  }

  React.useEffect(() => {
    const fetchTutorialData = async () => {
      const userDocRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user'),
        'settings',
        'tutorials',
      )
      const docData = await getDoc(userDocRef)
      setShowTutorial(docData.data()?.show_course_schedule_tutorial)
    }
    fetchTutorialData()
    return () => setShowTutorial(false)
  }, [])
  return (
    <div>
      {/* Conditionally Rendered for only both RBHS and RU Students */}
      {isBoth === true ? (
        <RutgersTabs value={tab} onChange={handleChange} aria-label="Top Tab">
          <RutgersTab label="RU" value="ru" {...a11yProps(0)} />
          <RutgersTab label="RBHS" value="rbhs" {...a11yProps(1)} />
        </RutgersTabs>
      ) : null}
      {tab !== 'rbhs' ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.selectContainer}>
            <InputLabel id="campus-menu-select" htmlFor="course-term">
              Course Term
            </InputLabel>
            <Select
              labelId="campus-menu-select"
              value={currentTerm}
              onChange={handleCourseTermChange}
              className={classes.selector}
              inputProps={{
                name: 'course-terms',
                id: 'controlled-native',
              }}
            >
              {scheduleData.ruTerms && scheduleData.ruTerms.length > 0
                ? scheduleData.ruTerms.map((course, index) => (
                    <MenuItem key={index} value={index}>
                      {course}
                    </MenuItem>
                  ))
                : undefined}
            </Select>
          </Grid>
        </Grid>
      ) : null}

      {scheduleData?.ruHolds ? (
        <>
          {scheduleData?.ruHolds?.map((hold) => (
            <Grid item style={{ width: '100%' }}>
              <Card
                className={classes.award}
                style={{ textAlign: 'center', alignItems: 'center' }}
                variant="outlined"
              >
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <IconPicker
                      iconName={hold?.icon?.icon || 'exclamation-circle'}
                      color={hold?.icon?.color || '#cc0033'}
                      size="5x"
                    ></IconPicker>
                  </Grid>
                  <Grid item>
                    <Text
                      style={{ marginTop: '10px', marginBottom: '5px' }}
                      className={classes.titleStyle}
                      variant="body1"
                    >
                      {hold.title}
                    </Text>
                  </Grid>
                  <Grid item>
                    <Text variant="body1">
                      {hold.message
                        .split('https://personalinfo.rutgers.edu.')
                        .map((segment, index, array) => {
                          const key = `segment_${index}_${segment}`
                          return (
                            <React.Fragment key={key}>
                              {segment}
                              {index !== array.length - 1 && (
                                <u>
                                  <a
                                    href="https://personalinfo.rutgers.edu."
                                    style={{
                                      fontWeight: 'bold',
                                      color: 'black',
                                    }}
                                  >
                                    https://personalinfo.rutgers.edu.
                                  </a>
                                </u>
                              )}
                            </React.Fragment>
                          )
                        })}
                    </Text>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </>
      ) : (
        <>
          <Grid
            container
            className={
              classes[`${isBoth === true ? 'both' : ''}${tab}ListContainer`]
            }
          >
            {courseList && courseList[tab]?.length > 0 ? (
              courseList[tab]?.map((course, index) => (
                <CourseObject
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${course.courseTitle}-${index}`}
                  course={course}
                  greyBackground={index % 2 === 0}
                  dispatch={dispatch}
                  widgetId={widgetId}
                  isMobile={isMobile}
                />
              ))
            ) : (
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                width="100%"
                marginTop="20px"
              >
                <IconPicker iconName="check-circle" color="#00b339" size="5x" />
                <Text variant="subtitle1">No Course Schedule Data</Text>
              </Box>
            )}
          </Grid>
          {/* Course DeepLinks */}
          <Grid className={classes.bottomListContainer}>
            <Grid item style={{ flex: 1 }}>
              <Button onClick={handleSelfReport}>
                <WorkOutlineOutlined />
                <Text className={classes.bottomListText}>
                  Self Reporting Absence
                </Text>
              </Button>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              {tab === 'ru' ? (
                <Button onClick={handleExportSchedule}>
                  <DateRangeIcon />
                  <Text className={classes.bottomListText}>
                    Export Course Schedule
                  </Text>
                </Button>
              ) : null}
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Button onClick={handleExpandedCourseView}>
                <SettingsOverscanIcon />
                <Text className={classes.bottomListText}>
                  Expand Course Schedule
                </Text>
              </Button>
            </Grid>
          </Grid>
          {courseModalOpen === true && (
            <CourseScheduleModal widgetId={widgetId} />
          )}
          {showTutorialBool === true ? (
            <TutorialMode
              showTutorial={showTutorialBool}
              steps={tutorialModeSteps}
              styles={tutorialClasses}
              stateEndTutorial={stateEndTutorial}
              dispatch={dispatch}
            />
          ) : null}
        </>
      )}
    </div>
  )
}

export default CourseSchedule
